import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, Hidden, IconButton, Toolbar, Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { translate } from 'react-switch-lang';
import Language from '../../shared/translations';
import MenuButtons from '../components/MenuButtons';

// eslint-disable-next-line react/prefer-stateless-function
class Header extends Component {
  render() {
    const { openMainMenu } = this.props;
    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className="menuButton" color="inherit" aria-label="menu" onClick={openMainMenu}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className="menuTitle">
            &nbsp;
          </Typography>
          <Language />
          <Hidden mdDown>
            <MenuButtons />
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  openMainMenu: PropTypes.func.isRequired,
};

export default translate(withRouter(observer(Header)));
