import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { requestPost } from '../../../shared/real.backend';

class CartStorage {
  id = null;

  user_id = null;

  itemsCount = 0;

  total = 0;

  status = 0;

  products = {};

  cartItems = {};

  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get currentCart() {
    return Object.keys(this.cartItems).map((key) => this.cartItems[key]);
  }

  loadCart = () => {
    this.loading = true;
    requestPost('/api/cart/current', [])
      .then((response) => {
        const { status, cartItems } = response;
        runInAction(() => {
          cartItems.map((item) => {
            const { product, ...cartItem } = item;
            this.addProductToCart(product);
            this.addItemToCart(cartItem);
          });
          this.loading = false;
        });
      });
  };

  addToCart = (itemID, quantity = 1) => requestPost('/api/cart/add', {
    product_id: itemID,
    quantity,
  })
    .then((response) => {
      runInAction(() => {
        const { cartItem } = response;
        const { product } = response;
        this.addProductToCart(product);
        this.addItemToCart(cartItem);
      });
    });

  removeFromCart = (itemID, quantity = -1) => requestPost('/api/cart/add', {
    product_id: itemID,
    quantity,
  })
    .then((response) => {
      runInAction(() => {
        const { cartItem } = response;
        const { product } = response;
        this.products[product.id] = product;
        this.cartItems[cartItem.product_id] = cartItem;
      });
    });

  addItemToCart = (cartItem) => this.cartItems[cartItem.product_id] = cartItem;

  addProductToCart = (product) => this.products[product.id] = product;

  createOrder = () => requestPost('/api/cart/createOrder', {})
    .then((response) => {
      if (response && response.status == 'success') {
        this.clearCart();
        return {
          status: response.status,
          order: response.order,
        };
      }
      toast.error(response.message || 'Error');
      return response;
    });

  clearCart = () => {
    this.itemsCount = 0;
    this.total = 0;
    this.status = 0;
    this.products = {};
    this.cartItems = {};
  };
}

const cartStorage = new CartStorage();

export default cartStorage;
