import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  Button,
} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import organizationsStorage from '../../shared/storages/OrganizationsStorage';
import CreateOrganization from '../containers/CreateOrganization';
import AddBrandDialog from '../components/AddBrandDialog';
import BrandAndAccountList from '../containers/BrandAndAccountList';
import OrganizationBrandsCount from '../linkedComponents/OrganizationBrandsCount';
import OrganizationAccountsCount from '../linkedComponents/OrganizationAccountsCount';

class OrganizationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedList: [],
    };
  }

  componentDidMount() {
    organizationsStorage.loadOrganizations();
  }

  expandableRow = (rowData, { rowIndex, dataIndex }) => {
    const colSpan = rowData.length + 1;
    return (
      <BrandAndAccountList
        colSpan={colSpan}
        rowIndex={rowIndex}
        dataIndex={dataIndex}
      />
    );
  };

  render() {
    const options = {
      expandableRows: true,
      expandableRowsHeader: false,
      expandableRowsOnClick: false,
      isRowExpandable: (dataIndex, expandedRows) => true,
      renderExpandableRow: this.expandableRow,
      downloadOptions: { separator: ';' },
      onDownload: (buildHead, buildBody, columns, data) => `\uFEFF${buildHead(columns)}${buildBody(data)}`,
      onRowsDelete: ({ data, lookup }, rowsData) => organizationsStorage.removeOrganizationBulk(data.map((d) => d.dataIndex)),
      onRowExpansionChange: (currentRowsExpanded, allRowsExpanded, rowsExpanded) => {
        // console.log('EXPAND ', currentRowsExpanded, allRowsExpanded, rowsExpanded);
        this.setState(() => ({ expandedList: allRowsExpanded }));
      },
      rowsExpanded: this.state.expandedList.map((row) => row.index),
    };
    const columns = [
      {
        name: 'ID',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const organization = organizationsStorage.organizationsList[dataIndex];
            if (!organization) return <span>No</span>;
            return organization.id;
          },
        },
      },
      {
        name: 'name',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const organization = organizationsStorage.organizationsList[dataIndex];
            if (!organization) return <span>No</span>;
            return organization.organizationName;
          },
        },
      },
      {
        name: 'accounts',
        options: {
          filter: false,
          // display: false,
          customBodyRenderLite: (dataIndex) => <OrganizationAccountsCount dataIndex={dataIndex} />,
        },
      },
      {
        name: 'brands',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => <OrganizationBrandsCount dataIndex={dataIndex} />,
        },
      },
      {
        name: 'Actions',
        options: {
          filter: false,
          empty: true,
          setCellProps: (value) => ({
            style: {
              // borderRight: '2px solid blue'
              width: '300px',
            },
          }),
          customBodyRenderLite: (dataIndex) => <AddBrandDialog dataIndex={dataIndex} />,
        },
      },
    ];
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={organizationsStorage.createOrganization}>Add</Button>
          <Button variant="outlined" onClick={() => organizationsStorage.loadOrganizations()}>Reload</Button>
        </div>
        {organizationsStorage.mode === 'add' && <CreateOrganization />}
        <MUIDataTable
          title="Products"
          data={organizationsStorage.organizationsList}
          columns={columns}
          options={options}
        />
      </div>
    );
  }
}

OrganizationsPage.propTypes = {};

export default observer(OrganizationsPage);
