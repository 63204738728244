import React from 'react';
import { Button } from '@material-ui/core';
import Img from '../assets/images/add_photo.png';

function UploadImageArea({ onChange, id }) {
  return (
    <div className="color-uploadImage mb-10">
      <input
        id={`Upload-${id}`}
        type="file"
        accept=".xls,.xlsx,.csv"
        onChange={onChange}
        title=""
        style={{
          display: 'none',
        }}
      />
      <Button
        variant="contained"
        color="primary"
        className="color-uploadImageFake"
        onClick={() => {
          document.getElementById(`Upload-${id}`).click();
        }}
      >
        <img src={Img} alt="" height={30} />
      </Button>
    </div>
  );
}

UploadImageArea.propTypes = {};

export default UploadImageArea;
