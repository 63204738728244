/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Box, Button, Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { ClipboardToApi } from '@garphild/garphild-ui';
import TextInput from './TextInput';
import currentProduct from '../../shared/storages/CurrentProduct';
import FileCapture from '../../shared/containers/FileCapture';
import ImageModel from '../../shared/models/ImageModel';
import UploadImageArea from './UploadImageArea';
import { uploadForm } from '../../shared/real.backend';

class CostInput extends React.PureComponent {
  state = {
    opened: false,
  };

  onchange = (e) => {
    const { index } = this.props;
    const { id: productId } = currentProduct.colors[index];
    const file = e.target.files[0];
    // eslint-disable-next-line no-unused-vars
    const imageModel = new ImageModel(file, productId);
    // this.drawOnCanvas(file); // see Example 6
    // displayAsImage(file);
  };

  upload = (file) => {
    const form = new FormData();
    const xhr = new XMLHttpRequest();

    form.append('image', file);
    xhr.open('post', 'api/image/upload', true);
    xhr.send(form);
  };

  render() {
    const {
      index,
      selected,
      onSelect,
    } = this.props;
    const {
      id, color, changeField, inStock, changed,
    } = currentProduct.colors[index];
    // const index = currentProduct.colors.findIndex((product) => product.id == productId);
    const { opened } = this.state;
    const cn = classnames({
      [`colors-${index}`]: true,
      changed,
      showAdditionalData: opened,
      selectedColor: selected,
    });
    return (
      <>
        <FormGroup className={cn} onClick={() => onSelect(id)}>
          <ClipboardToApi
            // apiUrl="https://2740-77-120-241-19.ngrok.io/api/images/upload"
            onFileFound={(file) => {
              const { id: productId } = currentProduct.colors[index];
              const imageModel = new ImageModel(file, productId);
              // const formData = new FormData();
              // formData.append('file', file);
              // uploadForm(
              //   'https://2740-77-120-241-19.ngrok.io/api/images/upload',
              //   formData,
              // )
              //   .then((resp) => {
              //     toast.success('Uploaded');
              //   });
            }}
            enabled={selected}
          >
            Test
          </ClipboardToApi>
          <TextInput placeholder="Colour" onInput={(e) => changeField('color', e.target.value)} value={color} />
          <br />
          <Grid container>
            <Grid item xs={6}>
              <UploadImageArea
                onChange={this.onchange}
                id={index}
              />
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <div className="prependIcon">pcs</div>
                <TextInput
                  placeholder="In stock"
                  onInput={(e) => changeField('inStock', e.target.value)}
                  value={inStock != 0 ? inStock : ''}
                  type="number"
                />
              </Box>
            </Grid>
          </Grid>
          {id !== 0 && <FileCapture productId={id} />}
        </FormGroup>
      </>
    );
  }
}

CostInput.defaultProps = {
};
CostInput.propTypes = {
  index: PropTypes.number.isRequired,
};

export default observer(CostInput);
