import { makeAutoObservable } from 'mobx';
import currentProduct from '../storages/CurrentProduct';

class ColorModel {
  id = 0;

  color = '';

  comment = ''

  images = [];

  inStock = 0;

  barcode = '';

  changed = false;

  constructor(product = null) {
    if (product) {
      if (product.id) this.id = product.id;
      if (product.color) this.color = product.color;
      if (product.comment) this.comment = product.comment;
      if (product.images) this.images = product.images;
      if (product.inStock) this.inStock = product.inStock;
      if (product.barcode) this.barcode = product.barcode;
    }
    makeAutoObservable(this);
  }

  changeField = (field, value) => {
    if (this[field] !== undefined) {
      switch (field) {
        case 'inStock':
          if (value == '') {
            this[field] = 0;
          } else {
            const val = Math.abs(parseInt(value.trim(), 10));
            if (val >= 0) {
              this[field] = val;
            }
          }
          break;
        default:
          this[field] = value;
      }
      currentProduct.setChanged();
      this.changed = true;
    }
  }
}

export default ColorModel;
