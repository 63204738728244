import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { translate } from 'react-switch-lang';
import { observer } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import ordersStorage from '../pages/orders/OrdersStorage';

class OrderStatusSelector extends Component {
  onChange = (e) => {
    const { index, onClose } = this.props;
    e.preventDefault();
    e.stopPropagation();
    ordersStorage.changeOrderStatus(index, e.target.value);
    if (onClose) onClose();
  };

  onClose = (e) => {
    const { onClose } = this.props;
    e.preventDefault();
    e.stopPropagation();
    if (onClose) onClose();
  };

  render() {
    const { t, index, onClose } = this.props;
    return (
      <div className="flex">
        <Select
          native
          fullWidth
          value={ordersStorage.ordersList[index].status}
          onChange={this.onChange}
        >
          <option value={-1}>{t('orderStatus.Payment error')}</option>
          <option value={0}>{t('orderStatus.Unknown')}</option>
          <option value={1}>{t('orderStatus.Wait payment')}</option>
          <option value={2}>{t('orderStatus.Prepaid')}</option>
          <option value={3}>{t('orderStatus.Fully paid')}</option>
          <option value={4}>{t('orderStatus.Sended')}</option>
          <option value={5}>{t('orderStatus.Delivered')}</option>
        </Select>
        {onClose && <IconButton onClick={this.onClose}><Close /></IconButton>}
      </div>
    );
  }
}

OrderStatusSelector.propTypes = {
  t: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default translate(observer(OrderStatusSelector));
