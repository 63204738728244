import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, GridList, GridListTile } from '@material-ui/core';
import ImageModel from '../models/ImageModel';
import currentProduct from '../storages/CurrentProduct';
import Img from '../../application/assets/images/add_photo.png';

class FileCapture extends Component {
  // drawOnCanvas = (file) => {
  //   const reader = new FileReader();
  //
  //   reader.onload = function (e) {
  //     const dataURL = e.target.result;
  //     const c = document.querySelector('canvas'); // see Example 4
  //     const ctx = c.getContext('2d');
  //     const img = new Image();
  //
  //     img.onload = function () {
  //       c.width = img.width;
  //       c.height = img.height;
  //       ctx.drawImage(img, 0, 0);
  //     };
  //
  //     img.src = dataURL;
  //   };
  //
  //   reader.readAsDataURL(file);
  // }

  render() {
    const { productId } = this.props;
    const index = currentProduct.colors.findIndex((product) => product.id == productId);
    return (
      <>
        <div
          className="color-imagesList"
          style={{
            display: 'flex', marginTop: 10, flexDirection: 'row', flexWrap: 'wrap',
          }}
        >
          {currentProduct.colors[index]
          && currentProduct.colors[index].images
          && currentProduct.colors[index].images.map
          && currentProduct.colors[index].images.map((image) => (
          //   <a href={image.name} target="_blank">
          //     <img src={image.name} alt="" width="100px" />
          //   </a>
          // ))}

            <div className="thumbnail-block">
              <img className="img-thumbnail" src={image.name} />
              <i className="fa fa-times thumbnail-block-close" onClick={() => currentProduct.removeImage(index, image.id)} />
            </div>
          ))}
        </div>
      </>
    );
  }
}

FileCapture.propTypes = {};

export default observer(FileCapture);
