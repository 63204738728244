import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import * as PropTypes from 'prop-types';
import { translate } from 'react-switch-lang';
import TextInput from '../components/TextInput';

function AddAccountDialog({
  onSave,
  onCancel,
  t,
}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  return (
    <div>
      <TextInput
        id="name"
        placeholder={t('Name')}
        onInput={(e) => setName(e.target.value)}
        value={name}
      />
      <TextInput
        id="account"
        placeholder={t('Email')}
        onInput={(e) => setEmail(e.target.value)}
        value={email}
      />
      <TextInput
        id="password"
        type="password"
        placeholder={t('Password')}
        onInput={(e) => setPassword(e.target.value)}
        value={password}
      />
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          onSave(name, email, password)
            .then((resp) => {
              if (resp !== true) {
                toast.error(resp);
              } else {
                setName('');
                setEmail('');
                setPassword('');
              }
            });
        }}
      >
        Save Account
      </Button>
      <Button variant="outlined" size="small" onClick={onCancel}>
        {t('Close')}
      </Button>
    </div>
  );
}

AddAccountDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(AddAccountDialog);
