import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { requestPost } from '../real.backend';

class CurrentUserStorage {
  id = 0;

  email = '';

  name = '';

  isLogged = false;

  isLoaded = false;

  roles = [];

  organization_id = -1;

  forInvoice = {
    companyName: 'NewLand',
    addr: 'Addr',
    zip: '01001',
    phone: '+555 7 789-1234',
    www: 'https://opt.moda',
    email: 'hello@dinostore.bed',
    number: '001',
    payerName: 'Slate Rock and Gravel Company',
    payerAddr1: '222 Rocky Way',
    payerAddr2: '30000 Bedrock, Cobblestone County',
    payerPhone: '+555 7 123-5555',
    payerEmail: 'fred@slaterockgravel.bed',
    payerPerson: 'Fred Flintstone',
    currency: 'USD',
    comment: 'Fred,<br /> thank you very much. We really appreciate your business.',
    bankAcc: '123006705',
    bankIBAN: 'US100000060345',
    bankSwift: 'BOA447',
    thanksFooter: 'Thanks!',
  };

  get isAdmin() {
    return this.roles.findIndex((role) => role.name == 'admin') >= 0;
  }

  get isOrganization() {
    return this.roles.findIndex((role) => role.name == 'org') >= 0;
  }

  get isCustomer() {
    return this.roles.findIndex((role) => role.name == 'customer') >= 0;
  }

  get isGuest() {
    return this.roles.findIndex((role) => role.name == 'guest') >= 0;
  }

  constructor() {
    makeAutoObservable(this);
  }

  loadCurrentUser = () => requestPost('/api/user', {
  })
    .then((resp) => {
      const { status, user } = resp;
      console.log(resp);
      if (status === 'fail') {
        toast.error(resp.message);
        this.isLoaded = true;
        window.location.href = '#/login';
        return false;
      }
      if (status === 'success') {
        this.isLoaded = true;
        this.isLogged = true;
        this.id = user.id;
        this.email = user.email;
        this.name = user.name;
        this.roles = user.roles;
        this.organization_id = user.organization_id;
        toast.success(resp.message);
        return true;
      }
    })
}

const currentUserStorage = new CurrentUserStorage();

export default currentUserStorage;
