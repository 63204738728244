/* eslint-disable eqeqeq */
import { runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { uploadForm } from '../real.backend';
import currentProduct from '../storages/CurrentProduct';

class ImageModel {
  id = 0;

  name = null;

  size = 0;

  width = 0;

  height = 0;

  constructor(file, productId) {
    this.uploadFile(file, productId);
  }

  uploadFile = (file, productId) => {
    const form = new FormData();
    form.append('image', file);
    uploadForm(`/api/products/${productId}/image/upload`, form)
      .then((resp) => {
        runInAction(() => {
          const index = currentProduct.colors.findIndex((color) => color.id == productId);
          if (index >= 0) {
            currentProduct.colors[index].images = resp.images;
          }
          if (currentProduct.changed) currentProduct.saveProduct();
          toast.success('Image saved');
        });
      })
      .catch(() => null);
  };
}

export default ImageModel;
