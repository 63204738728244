import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';

// eslint-disable-next-line react/prefer-stateless-function
class CostInput extends Component {
  render() {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <TextInput {...this.props} />
    );
  }
}

CostInput.defaultProps = {
  type: 'text',
  // eslint-disable-next-line react/default-props-match-prop-types
  id: null,
  label: null,
  placeholder: '',
  onInput: () => {},
  // eslint-disable-next-line react/default-props-match-prop-types
  value: '',
};
CostInput.propTypes = {
  type: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onInput: PropTypes.func,
};

export default CostInput;
