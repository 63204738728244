import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

class SimpleOrderPage extends Component {
  render() {
    const { match } = this.props.history;
    return (
      <div>
        Simple order
      </div>
    );
  }
}

SimpleOrderPage.propTypes = {};

export default withRouter(observer(SimpleOrderPage));
