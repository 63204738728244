import React from 'react';

class TablesIcon extends React.Component {

    render() {
        return (
            <svg className={this.props.className} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.5 15.8333V12.5H15.8333L15.8342 15.8333H12.5ZM15.8333 10.8333H12.5C11.5808 10.8333 10.8333 11.5808 10.8333 12.5V15.8333C10.8333 16.7525 11.5808 17.5 12.5 17.5H15.8333C16.7525 17.5 17.5 16.7525 17.5 15.8333V12.5C17.5 11.5808 16.7525 10.8333 15.8333 10.8333ZM4.16667 15.8333V12.5H7.5L7.50083 15.8333H4.16667ZM7.5 10.8333H4.16667C3.2475 10.8333 2.5 11.5808 2.5 12.5V15.8333C2.5 16.7525 3.2475 17.5 4.16667 17.5H7.5C8.41917 17.5 9.16667 16.7525 9.16667 15.8333V12.5C9.16667 11.5808 8.41917 10.8333 7.5 10.8333ZM12.5 7.5V4.16667H15.8333L15.8342 7.5H12.5ZM15.8333 2.5H12.5C11.5808 2.5 10.8333 3.2475 10.8333 4.16667V7.5C10.8333 8.41917 11.5808 9.16667 12.5 9.16667H15.8333C16.7525 9.16667 17.5 8.41917 17.5 7.5V4.16667C17.5 3.2475 16.7525 2.5 15.8333 2.5ZM4.16667 7.5V4.16667H7.5L7.50083 7.5H4.16667ZM7.5 2.5H4.16667C3.2475 2.5 2.5 3.2475 2.5 4.16667V7.5C2.5 8.41917 3.2475 9.16667 4.16667 9.16667H7.5C8.41917 9.16667 9.16667 8.41917 9.16667 7.5V4.16667C9.16667 3.2475 8.41917 2.5 7.5 2.5Z" />
                <mask id="table" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="16" height="16">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.5 15.8333V12.5H15.8333L15.8342 15.8333H12.5ZM15.8333 10.8333H12.5C11.5808 10.8333 10.8333 11.5808 10.8333 12.5V15.8333C10.8333 16.7525 11.5808 17.5 12.5 17.5H15.8333C16.7525 17.5 17.5 16.7525 17.5 15.8333V12.5C17.5 11.5808 16.7525 10.8333 15.8333 10.8333ZM4.16667 15.8333V12.5H7.5L7.50083 15.8333H4.16667ZM7.5 10.8333H4.16667C3.2475 10.8333 2.5 11.5808 2.5 12.5V15.8333C2.5 16.7525 3.2475 17.5 4.16667 17.5H7.5C8.41917 17.5 9.16667 16.7525 9.16667 15.8333V12.5C9.16667 11.5808 8.41917 10.8333 7.5 10.8333ZM12.5 7.5V4.16667H15.8333L15.8342 7.5H12.5ZM15.8333 2.5H12.5C11.5808 2.5 10.8333 3.2475 10.8333 4.16667V7.5C10.8333 8.41917 11.5808 9.16667 12.5 9.16667H15.8333C16.7525 9.16667 17.5 8.41917 17.5 7.5V4.16667C17.5 3.2475 16.7525 2.5 15.8333 2.5ZM4.16667 7.5V4.16667H7.5L7.50083 7.5H4.16667ZM7.5 2.5H4.16667C3.2475 2.5 2.5 3.2475 2.5 4.16667V7.5C2.5 8.41917 3.2475 9.16667 4.16667 9.16667H7.5C8.41917 9.16667 9.16667 8.41917 9.16667 7.5V4.16667C9.16667 3.2475 8.41917 2.5 7.5 2.5Z" />
                </mask>
                <g mask="url(#table)">
                    <rect width="20" height="20"/>
                </g>
            </svg>
        );
    }
}

export default TablesIcon;
