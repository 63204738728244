/* eslint-disable react/prefer-stateless-function,max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import { observer } from 'mobx-react';
import currentUserStorage from '../../shared/storages/CurrentUserStorage';
import { requestGet } from '../../shared/real.backend';

class MenuButtons extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        {(currentUserStorage.isAdmin || currentUserStorage.isOrganization)
        && <NavLink className="nav-link" color="inherit" to="/application/upload">{t('Upload')}</NavLink>}
        {(currentUserStorage.isAdmin || currentUserStorage.isOrganization)
        && <NavLink className="nav-link" color="inherit" to="/application/add">{t('Add')}</NavLink>}
        {(currentUserStorage.isAdmin || currentUserStorage.isOrganization)
        && <NavLink className="nav-link" color="inherit" to="/application/list">{t('List')}</NavLink>}
        {(currentUserStorage.isAdmin || currentUserStorage.isOrganization)
        && <NavLink className="nav-link" color="inherit" to="/application/profile">{t('Profile')}</NavLink>}
        {(currentUserStorage.isAdmin || currentUserStorage.isOrganization || currentUserStorage.isCustomer)
        && <NavLink className="nav-link" to="/shop">{t('Catalog')}</NavLink>}
        {(currentUserStorage.isAdmin || currentUserStorage.isOrganization || currentUserStorage.isCustomer)
        && <NavLink className="nav-link" to="/application/cart">{t('Cart')}</NavLink>}
        {(currentUserStorage.isAdmin || currentUserStorage.isOrganization || currentUserStorage.isCustomer)
        && <NavLink className="nav-link" to="/application/orders">{t('Orders')}</NavLink>}
        {currentUserStorage.isAdmin
        && <NavLink className="nav-link" to="/application/settings">{t('Settings')}</NavLink>}
        {currentUserStorage.isLoaded && currentUserStorage.id > 0
        && (
        <NavLink
          to="/logout"
          className="nav-link"
          onClick={() => {
            requestGet('/api/logout')
              .then(() => {
                localStorage.removeItem('zakaz_token');
                window.location.href = '/';
              });
          }}
        >
          {t('Log out')}
        </NavLink>
        )}
        {currentUserStorage.isLoaded && !currentUserStorage.id
        && (
        <>
          <NavLink
            to="/#/login"
            className="nav-link"
          >
            {t('Sign In')}
          </NavLink>
          <NavLink
            to="/#/register"
            className="nav-link"
          >
            {t('Register')}
          </NavLink>
        </>
        )}
      </>
    );
  }
}

MenuButtons.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(withRouter(observer(MenuButtons)));
