import { makeAutoObservable, runInAction } from 'mobx';
import { requestPost } from '../real.backend';

class ProductsListStorage {
  products = [];

  get getProducts() {
    return this.products.map((product) => [
      1,
      product.id,
      product.images,
      product.brand,
      product.article,
      product.color,
      product.sizes,
      product.inStock,
      product.price / 100,
      product.inPackage,
      product.barcode,
      product.comment,
    ]);
  }

  constructor() {
    makeAutoObservable(this);
  }

  loadProducts = () => {
    requestPost('/api/products/list', {})
      .then((resp) => {
        const { products } = resp;
        if (products) {
          runInAction(() => {
            this.products = [];
            products.map((item) => this.products.push(item));
          });
        }
      });
  }

  changePrice = (index, value) => {
    this.products[index].price = (value * 100);
  }

  changeStock = (index, value) => {
    this.products[index].inStock = value;
  }
}

const productsListStorage = new ProductsListStorage();

export default productsListStorage;
