/* eslint-disable camelcase,eqeqeq */
import { makeAutoObservable } from 'mobx';
import { requestPost } from '../../../shared/real.backend';

let timer = null;

class OrganizationOrderStorage {
  id = 0;

  organization_id = 0;

  items = [];

  products = {};

  name = '';

  surname = '';

  town = '';

  phone = '';

  email = '';

  addr = '';

  cargo = '';

  cargoCode = '';

  changed = {};

  constructor() {
    makeAutoObservable(this);
  }

  get isChanged() {
    return Object.keys(this.changed).length > 0;
  }

  setItem = (id, qty) => {
    const index = this.items.findIndex((item) => item.id == id);
    if (index < 0) {
      this.items.push({
        id,
        qty: +qty >= 0 ? +qty : 0,
      });
    } else {
      this.items[index].qty = +qty >= 0 ? +qty : 0;
    }
    this.changeUpdateTimer();
  };

  changeUpdateTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(this.saveItems, 500);
  };

  saveItems = () => {
    timer = null;
    requestPost('/api/organization/orders/current/items', {
      id: this.id,
      organization_id: this.organization_id,
      items: this.items,
    })
      .then((/* response */) => {
        // console.log(response);
      });
  };

  loadCurrentOrder = () => {
    requestPost('/api/organization/orders/current', {})
      .then((response) => {
        if (response && response.status === 'success') {
          const { order } = response;
          this.update(order);
        }
      });
  };

  update = (order) => {
    this.id = order.id;
    this.organization_id = order.organization_id;
    this.items = order.items
      ? order
        .items
        .map((item) => ({ id: item.product_id, qty: item.packs }))
      : [];
    this.products = order.products || {};
    this.name = order.name || '';
    this.surname = order.surname || '';
    this.town = order.town || '';
    this.phone = order.phone || '';
    this.email = order.email || '';
    this.addr = order.addr || '';
    this.cargo = order.cargo || '';
    this.cargoCode = order.cargoCode || '';
    this.changed = {};
  };

  changeField = (field, value) => {
    if (this[field] !== undefined) {
      this[field] = value;
      this.changed[field] = value;
    }
  };

  saveChanges = () => {
    requestPost('/api/organization/orders/current/save', {
      id: this.id,
      organization_id: this.organization_id,
      ...this.changed,
    })
      .then((response) => {
        if (response && response.status === 'success') {
          this.update(response.order);
        }
      });
  };

  newOrder = () => {
    // this.id = 0;
    // this.organization_id = 0;
    // this.items = [];
    // this.products = {};
    // this.name = '';
    // this.surname = '';
    // this.town = '';
    // this.phone = '';
    // this.email = '';
    // this.addr = '';
    // this.cargo = '';
    // this.cargoCode = '';
    // this.changed = {};
    requestPost('/api/organization/orders/current/place', {})
      .then((response) => {
        if (response && response.status == 'success') {
          this.update(response.order);
        }
      });
  };
}

const organizationOrderStorage = new OrganizationOrderStorage();

export default organizationOrderStorage;
