import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import axios from 'axios';
import { runInAction } from 'mobx';
import { translate } from 'react-switch-lang';
import { Button, Collapse } from 'reactstrap';
import { toast } from 'react-toastify';
import { ClipboardToApi } from '@garphild/garphild-ui';
import TextInputSelectorBrands from '../components/TextInputSelectorBrands';
import brandsStorage from '../../shared/storages/BrandsStorage';
import TextInputSelectorOrganizations from '../components/TextInputSelectorOrganizations';
import organizationsStorage from '../../shared/storages/OrganizationsStorage';
import UploadImageArea from '../components/UploadImageArea';
import { requestPost, uploadForm } from '../../shared/real.backend';
import mockedStats from '../../mocks/uploadedPrice.json';
import PairComposer from '../components/PairComposer';
import PairMapper from '../components/PairMapper';
import priceUploadStorage from '../../shared/storages/priceUploadStorage';
import Widget from '../../v2/components/Widget';

function UploadProductsFile({ t }) {
  const {
    currentBrand,
    setCurrentBrand,
    currentOrganization,
    setCurrentOrganization,
    currentFileName,
    setCurrentFileName,
    fileStat,
    setFileStat,
    fieldsMapper,
    setFieldsMap,
    fieldsDataMap,
    setMapToFieldData,
    allowFieldsToMapValues,
  } = priceUploadStorage;

  const [partOpened, setPartOpened] = useState(null);

  useEffect(() => {
    brandsStorage.loadBrands();
    organizationsStorage.loadOrganizations();
  }, []);

  const onchange = (e) => {
    const uploadUrl = '/api/xlxs/parse';
    const file = e.target.files[0];
    console.log('WREST', file);
    const form = new FormData();
    form.append('file', file);
    form.append('brand', currentBrand);
    form.append('organization', currentOrganization);
    uploadForm(uploadUrl, form)
      .then((resp) => {
        runInAction(() => {
          setCurrentFileName(resp.filename);
          setFileStat(resp.stats);
          if (resp.template?.fieldsMap) setFieldsMap(resp.template.fieldsMap);
          if (resp.template?.dataMap) setMapToFieldData(resp.template.dataMap);
          toast.success('File saved');
        });
      })
      .catch(() => null);
  };

  const onSave = () => {
    requestPost('/api/xlxs/process', priceUploadStorage.mapData)
      .then((data) => {
        toast.success('All data saved');
      });
  };

  return (
    <Widget>
      {/* <ClipboardToApi */}
      {/*  // apiUrl="https://2740-77-120-241-19.ngrok.io/api/images/upload" */}
      {/*  onFileFound={(file) => { */}
      {/*    const formData = new FormData(); */}
      {/*    formData.append('file', file); */}
      {/*    uploadForm( */}
      {/*      'https://2740-77-120-241-19.ngrok.io/api/images/upload', */}
      {/*      formData, */}
      {/*    ) */}
      {/*      .then((resp) => { */}
      {/*        toast.success('Uploaded'); */}
      {/*      }); */}
      {/*  }} */}
      {/*  enabled */}
      {/* > */}
      {/*  Test */}
      {/* </ClipboardToApi> */}
      <div>
        <TextInputSelectorBrands
          id="brand"
          disabled={false}
          placeholder="Brand"
          onInput={(e) => setCurrentBrand(e.target.value)}
          value={currentBrand}
          options={brandsStorage.brandsList.map((b) => b.brand)}
        />
      </div>
      {currentBrand && (
      <div>
        <TextInputSelectorOrganizations
          id="organization"
          disabled={false}
          placeholder="Organization"
          onInput={(e) => setCurrentOrganization(e.target.value)}
          value={currentOrganization}
          options={organizationsStorage.organizationsList.map((b) => b.organizationName)}
        />
      </div>
      )}
      {currentBrand && currentOrganization && (
      <div>
        <div>{currentFileName}</div>
        <UploadImageArea
          onChange={onchange}
          id="price upload"
        />
      </div>
      )}
      {currentBrand && currentOrganization && fileStat && (
      <div>
        <strong>{t('Fields')}</strong>
        {Object.entries(fieldsMapper).map((entry) => (
          <PairComposer
            from={entry[0]}
            to={entry[1]}
            onChange={(field, value) => {
              console.log('WREST', field, value);
              setFieldsMap({ ...fieldsMapper, [field]: value });
            }}
          />
        ))}
      </div>
      )}
      {Object.entries(fileStat)
        .filter((entry) => !!allowFieldsToMapValues[fieldsMapper[entry[0]]])
        .length > 0 && (
        <div>
          <strong>ValuesMappers</strong>
          {Object.entries(fileStat)
            .filter((entry) => !!allowFieldsToMapValues[fieldsMapper[entry[0]]])
            .map((entry) => (
              <>
                <div
                  style={{
                    backgroundColor: 'gray',
                    padding: 5,
                    marginBottom: 10,
                    display: 'flex',
                    justifyContent: 'space-between',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                  }}
                  onClick={() => setPartOpened(partOpened === entry[0] ? null : entry[0])}
                >
                  <i>{entry[0]}</i>
                  <i
                    style={{
                      transform: partOpened === entry[0] ? 'rotate(90deg)' : 'rotate(270deg)',
                    }}
                  >
                    &lt;
                  </i>
                </div>
                <Collapse isOpen={partOpened === entry[0]}>
                  {partOpened === entry[0] && Object.keys(entry[1]).filter((pairKey) => !!(pairKey.trim())).map((pairKey) => (
                    <PairMapper
                      key={pairKey}
                      from={pairKey}
                      to={fieldsDataMap[entry[0]]?.[pairKey]}
                      onChange={(field, value) => {
                        console.log('WREST', field, value, fieldsDataMap);
                        if (!fieldsDataMap[entry[0]]) fieldsDataMap[entry[0]] = {};
                        fieldsDataMap[entry[0]][pairKey] = value;
                        setMapToFieldData({ ...fieldsDataMap });
                      }}
                    />
                  ))}
                </Collapse>
              </>
            ))}
        </div>
      )}
      <Button disabled={!(currentBrand && currentOrganization && fileStat)} onClick={onSave}>Save</Button>
    </Widget>
  );
}

UploadProductsFile.propTypes = {};

export default translate(observer(UploadProductsFile));
