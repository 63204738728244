import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@material-ui/core';
import { observer } from 'mobx-react';
import { translate } from 'react-switch-lang';
import TabPanel from '../../shared/components/TabPanel';
import OrganizationsPage from './OrganizationsPage';
import BrandsPage from './BrandsPage';
import SystemSettingsPage from './SystemSettingsPage';
import Editor from './Editor';
import a11yProps from '../../shared/helpers/a11yProps';
import currentUserStorage from '../../shared/storages/CurrentUserStorage';
import Forbidden from '../containers/Forbidden';
import Statuses from './settings/Statuses';

class Settings extends Component {
  state = {
    tabIndex: 4,
  };

  changeTabIndex = (event, index) => this.setState(() => ({ tabIndex: index }));

  render() {
    if (!currentUserStorage.isAdmin) return <Forbidden />;
    const { t } = this.props;
    const { tabIndex } = this.state;
    return (
      <div>
        <Tabs
          value={tabIndex}
          onChange={this.changeTabIndex}
          aria-label="simple tabs example"
          wrapped
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label={t('settings.Organizations')} {...a11yProps(0)} />
          <Tab label={t('settings.Brands & sizes')} {...a11yProps(1)} />
          <Tab label={t('settings.System settings')} {...a11yProps(2)} />
          <Tab label={t('settings.Editor')} {...a11yProps(3)} />
          <Tab label={t('settings.Statuses')} {...a11yProps(4)} />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <OrganizationsPage />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <BrandsPage />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <SystemSettingsPage />
        </TabPanel>
        <TabPanel value={tabIndex} index={3}>
          <Editor />
        </TabPanel>
        <TabPanel value={tabIndex} index={4}>
          <Statuses />
        </TabPanel>
      </div>
    );
  }
}

Settings.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(observer(Settings));
