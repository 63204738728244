import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import TrashIcon from '@material-ui/icons/DeleteForever';
import { Paper } from '@material-ui/core';
import { translate } from 'react-switch-lang';
import cartStorage from './cartStorage';
import { formatDateFromString } from '../../../shared/helpers/formatDate';

class CartItem extends Component {
  render() {
    const { index, t } = this.props;
    console.log('CI:', cartStorage.cartItems, index);
    const { id, packs, product_id } = cartStorage.cartItems[index];
    if (!cartStorage.products[product_id]) return null;
    const {
      price, id: prid, brand, article, color, sizes, images, inPackage, attentionDate,
    } = cartStorage.products[product_id];
    const actualDate = formatDateFromString(attentionDate);

    return (
      <div className="cart-item__container">
        <div className="cart-item__first">
          <div>{actualDate}</div>
          <div>{article}</div>
          <div>{brand}</div>
        </div>
        <div className="cart-item__main">
          <div className="cart-item__image">
            <img src={images && images.length > 0 ? images[0].thumbnail : null} alt="" />
          </div>
          <div className="cart-item__main-info">
            <div className="cart-item__row">
              <div>{color}</div>
              <div>
                $
                {(price / 100).toFixed(2)}
              </div>
            </div>
            <div className="cart-item__row">
              <div className="cart-item__trash" onClick={() => cartStorage.removeFromCart(prid, -1 * packs)}><TrashIcon fontSize="large" /></div>
              <div className="cart-item__button cart-item__button_minus" onClick={() => cartStorage.removeFromCart(prid)}>
                -
              </div>
              <div>
                {packs}
                {' '}
                {t('cart.Packs')}
                {' '}
                (x
                {' '}
                {inPackage}
                {' '}
                {t('cart.Qty')}
                )
              </div>
              <div className="cart-item__button cart-item__button_plus" onClick={() => cartStorage.addToCart(prid)}>
                +
              </div>
            </div>
          </div>

        </div>
        <div className="cart-item__additional">
          <div className="cart-item__additional-sizes">{sizes}</div>
          <div>
            $
            {(price * packs * inPackage / 100).toFixed(2)}
          </div>
        </div>
      </div>
    );
  }
}

CartItem.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(observer(CartItem));
