import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate } from 'react-switch-lang';
import organizationOrderStorage from './OrganizationOrderStorage';
import productsListStorage from '../../../shared/storages/ProductsListStorage';

// eslint-disable-next-line react/prefer-stateless-function
class OrderItems extends Component {
  render() {
    const {
      items,
    } = organizationOrderStorage;
    const {
      products,
    } = productsListStorage;
    return (
      <div>
        {items.length > 0 ? <h3>Items</h3> : null}
        {items.map((item) => {
          const product = products.find((p) => p.id === item.id);
          return (item.qty > 0
            ? (
              <div>
                {product ? product.brand : ''}
                /
                {product ? product.color : ''}
                :
                {item.qty}
              </div>
            ) : null
          );
        })}
      </div>
    );
  }
}

OrderItems.propTypes = {};

export default translate(observer(OrderItems));
