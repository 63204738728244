/* eslint-disable react/forbid-prop-types,eqeqeq */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import sizesStorage from '../../shared/storages/SizesStorage';
import ChipedAutocompleteInput from './simple/ChipedAutocompleteInput';

class TextInputSelectorGlobalSizes extends Component {
  render() {
    const {
      minTextLenForShowVariants,
      allowShowList,
      delimiters,
      allowedListField,
      existingListField,
      onSelectSize,
      disabled,
      autoFocus,
      onSave,
      maxVariantsLength,
      type,
      placeholder,
      onlyGlobal,
      removeItem,
      variantsSource,
      filterType,
      onError,
    } = this.props;
    const sizes = sizesStorage.organizationSizes.filter((item) => {
      if (onlyGlobal === true) {
        return item.organization_id == 0;
      }
      return true;
    });
    return (
      <ChipedAutocompleteInput
        existingList={sizes}
        existingListField={existingListField || 'sizes'}
        allowedList={sizesStorage.sizes}
        allowedListField={allowedListField || 'sizes'}
        delimiters={delimiters || []}
        minTextLenForShowVariants={minTextLenForShowVariants}
        allowShowList={allowShowList}
        onSelectSize={onSelectSize}
        disabled={disabled}
        autoFocus={autoFocus}
        onSave={onSave}
        maxVariantsLength={maxVariantsLength}
        type={type}
        placeholder={placeholder}
        removeItem={removeItem}
        variantsSource={variantsSource || sizes}
        filterType={filterType}
        onError={onError}
      />
    );
  }
}

TextInputSelectorGlobalSizes.defaultProps = {
  type: 'text',
  // eslint-disable-next-line react/default-props-match-prop-types
  id: null,
  label: null,
  placeholder: '',
  onInput: () => {},
  onFocus: () => {},
  allowShowVariants: true,

  minTextLenForShowVariants: null,
  allowShowList: false,
  onSelectSize: () => {},
  autoFocus: false,
  onSave: () => {},
  removeItem: () => {},
  onError: () => {},
  filterType: [],
  variantsSource: [],
  onlyGlobal: true,
  maxVariantsLength: 10,
  disabled: true,
  existingListField: null,
  allowedListField: null,
  delimiters: [],
};
TextInputSelectorGlobalSizes.propTypes = {
  type: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  label: PropTypes.string,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  onInput: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  onFocus: PropTypes.func,
  minTextLenForShowVariants: PropTypes.number,
  allowShowList: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  allowShowVariants: PropTypes.bool,
  delimiters: PropTypes.array,
  allowedListField: PropTypes.array,
  existingListField: PropTypes.array,
  onSelectSize: PropTypes.func,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onSave: PropTypes.func,
  maxVariantsLength: PropTypes.number,
  onlyGlobal: PropTypes.bool,
  removeItem: PropTypes.func,
  variantsSource: PropTypes.array,
  filterType: PropTypes.array,
  onError: PropTypes.func,
};

export default observer(TextInputSelectorGlobalSizes);
