import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import OrderStatus from './OrderStatus';
import ordersStorage from '../pages/orders/OrdersStorage';
import OrderStatusSelector from './OrderStatusSelector';

class OrderStatusChangeable extends Component {
  state = {
    opened: false,
  };

  onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.toggle();
  };

  toggle = () => this.setState((state) => ({ opened: !state.opened }));

  render() {
    const { index } = this.props;
    const { opened } = this.state;
    return (
      <div>
        {opened
          ? <OrderStatusSelector index={index} onClose={this.toggle} />
          : <OrderStatus status={ordersStorage.ordersList[index].status} onClick={this.onClick} />}
      </div>
    );
  }
}

OrderStatusChangeable.propTypes = {
  index: PropTypes.number.isRequired,
};

export default observer(OrderStatusChangeable);
