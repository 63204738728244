import React from 'react';
import { translate } from 'react-switch-lang';
import { Col, Row } from 'reactstrap';
import TextInputSelector from './TextInputSelector';

function PairComposer({
  from, to, t, onChange,
}) {
  return (
    <Row style={{
      borderBottom: '1px solid black',
      marginBottom: '5px',
      alignItems: 'center',
    }}
    >
      <Col>{from}</Col>
      <Col>
        <TextInputSelector
          style={{ marginBottom: '0px' }}
          id={`pairSelector-${from}`}
          label={null}
          value={to}
          disabled={false}
          options={[
            { value: 'brand', name: t('brand') },
            { value: 'article', name: t('article') },
            { value: 'color', name: t('color') },
            { value: 'sizes', name: t('sizes') },
            { value: 'price', name: t('price') },
            { value: 'attentionDate', name: t('attentionDate') },
            { value: 'comment', name: t('comment') },
            { value: 'inStock', name: t('inStock') },
            { value: 'inPackage', name: t('inPackage') },
            { value: 'name', name: t('name') },
            { value: 'image1', name: t('image1') },
          ]}
          onChange={(value) => onChange(from, value)}
          defaultOptionName={t('Ignore')}
          defaultOptionValue={null}
        />
      </Col>
    </Row>
  );
}

PairComposer.propTypes = {};

export default translate(PairComposer);
