import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion, AccordionDetails, AccordionSummary, Paper,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { translate } from 'react-switch-lang';
import ordersStorage from './OrdersStorage';
import OrderStatus from '../../components/OrderStatus';
import './OrderPage.scss';
import OrderDetailButton from '../../components/OrderDetailButton';
import OrderStatusChangeable from '../../components/OrderStatusChangeable';
import currentUserStorage from '../../../shared/storages/CurrentUserStorage';

class OrdersPage extends Component {
  componentDidMount() {
    ordersStorage.loadOrdersIfNotLoaded();
  }

  render() {
    const { currentOrderID, ordersList } = ordersStorage;
    const { t } = this.props;
    return (
      <div>
        <Paper>
          <div className="order-page__table order-page__tableHeader">
            <div>
              #
            </div>
            <div>
              {t('ordersTable.User')}
            </div>
            <div>
              {t('ordersTable.Packs')}
            </div>
            <div>
              {t('ordersTable.Order total')}
            </div>
            <div>
              {t('ordersTable.Order status')}
            </div>
          </div>
          {ordersList.map((order, index) => {
            const id = `panel-order-${order.id}`;
            return (
              <>
                <Accordion key={id} expanded={currentOrderID === order.id} onChange={() => ordersStorage.setCurrentOrder(order.id)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                  >
                    <div className="order-page__table" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <div>
                        #
                        {order.id}
                      </div>
                      <div>
                        {order.user.name}
                      </div>
                      <div>
                        {order.itemsCount}
                      </div>
                      <div>
                        {order.total / 100}
                        {' '}
                        /
                        {' '}
                        {order.prepaid / 100}
                      </div>
                      <div className="flex-right">
                        {currentUserStorage.isAdmin
                          ? <OrderStatusChangeable index={index} />
                          : <OrderStatus status={order.status} />}
                        <OrderDetailButton id={order.id} />
                        {/* {t('ordersTable.Order status')} */}
                        {/*:*/}
                        {/* {' '} */}
                        {/* {order.status} */}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {ordersStorage.loading ? <div>{t('Loading...')}</div>
                      : (
                        <table width="100%">
                          <thead>
                            <th>{t('Brand')}</th>
                            <th>{t('Article')}</th>
                            <th>{t('Color')}</th>
                            <th>{t('Sizes')}</th>
                            <th>{t('Packs')}</th>
                            <th>{t('Qty')}</th>
                            <th>{t('Price')}</th>
                            <th>{t('Total')}</th>
                          </thead>
                          <tbody>
                            {ordersStorage.currentOrderItems.map((item) => (
                              <tr>
                                <td>{item.product.brand}</td>
                                <td>{item.product.article}</td>
                                <td>{item.product.color}</td>
                                <td>{item.product.sizes}</td>
                                <td>{item.packs}</td>
                                <td>{item.packs * item.product.inPackage}</td>
                                <td>{(item.price / 100).toFixed(2)}</td>
                                <td>{(item.packs * item.product.inPackage * item.price / 100).toFixed(2)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })}
        </Paper>
      </div>
    );
  }
}

OrdersPage.propTypes = {};

export default translate(observer(OrdersPage));
