/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import NumberFormat from 'react-number-format';
import productsListStorage from '../../shared/storages/ProductsListStorage';
import { requestPost } from '../../shared/real.backend';

class EditableInStok extends Component {
  state = {
    edited: false,
    value: 0,
  };

  componentDidMount() {
    const { index } = this.props;
    this
      .setState({
        value: productsListStorage.products[index]
          ? productsListStorage.products[index].inStock
          : 0,
      });
  }

  toggleEdit = () => this.setState((state) => ({ edited: !state.edited }));

  changeValue = (value) => this.setState(() => ({ value: Math.floor(+value) }));

  savePrice = () => {
    const { index } = this.props;
    const { value } = this.state;
    requestPost('/api/products/updateStock', {
      id: productsListStorage.products[index].id,
      inStock: value,
    })
      .then(() => {
        productsListStorage.changeStock(index, value);
        this.setState({ edited: false });
      });
  };

  render() {
    const { index } = this.props;
    const { edited, value } = this.state;
    return (
      <>
        <div
          onClick={this.toggleEdit}
        >
          {!edited && productsListStorage.products[index].inStock}
        </div>
        {edited && (
          <div>
            <NumberFormat
              thousandSeparator
              prefix=""
              value={value}
              onValueChange={(values) => {
                this.changeValue(values.value);
              }}
            />
            <button type="button" onClick={this.savePrice}>
              <i className="fa fa-save" />
            </button>
          </div>
        )}
      </>
    );
  }
}

EditableInStok.propTypes = {
  index: PropTypes.number.isRequired,
};

export default observer(EditableInStok);
