import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import MUIDataTable from 'mui-datatables';
import { Button, Chip } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import productsListStorage from '../../shared/storages/ProductsListStorage';
import EditablePrice from '../components/EditablePrice';
import EditableInStok from '../components/EditableInStok';
import currentUserStorage from '../../shared/storages/CurrentUserStorage';
import OrderQty from '../linkedComponents/OrderQty';
import OrderAmount from '../linkedComponents/OrderAmount';
import OrderTotal from '../linkedComponents/OrderTotal';
import OrderSumm from '../linkedComponents/OrderSumm';
import localBrowserStorage from '../../shared/storages/LocalBrowserStorage';
import OrganizationOrders from '../containers/OrganizationOrders/OrganizationOrders';
import Widget from '../../v2/components/Widget';

class ProductsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: null,
    };
  }

  componentDidMount() {
    productsListStorage.loadProducts();
  }

  onDelete = ({ data, lookup }, rowsData) => console.log(data, lookup, rowsData);

  render() {
    const { history } = this.props;
    const { currentImage } = this.state;
    const columns = [
      {
        name: '#',
        options:
          {
            filter: false,
            sort:
              false,
            customBodyRenderLite: (dataIndex) => dataIndex + 1,
            className: 'test',
          },
      },
      {
        name: 'id',
        options:
          {
            filter: false,
            sort:
              true,
            display: currentUserStorage.isAdmin,
          },
      },
      {
        name: 'Images',
        options: {
          filter: false,
          // empty: true,
          customBodyRenderLite: (dataIndex) => {
            const product = productsListStorage.getProducts[dataIndex];
            if (!product) return <span>No</span>;
            if (product[2].length > 0) {
              return (
                <div style={{
                  display: 'flex', justifyContent: 'center'/* , width: '484px' */, overflow: 'hidden',
                }}
                >
                  {product[2].length === 1 && (
                    <>
                      <div className="carousel">
                        <div
                          className="carousel-image"
                          onMouseLeave={() => this.setState({ currentImage: null })}
                          onMouseEnter={() => this.setState({ currentImage: product[2][0].name.replace('/storage/public', '/storage') })}
                        >
                          <img src={product[2][0].name.replace('/storage/public', '/storage')} />
                        </div>
                      </div>
                      <div className="carousel-bigImage">
                        <img src={currentImage} />
                      </div>
                    </>
                  )}
                  {product[2].length === 2 && (
                    <>
                      <div className="carousel">
                        <div
                          className="carousel-image"
                          onMouseLeave={() => this.setState({ currentImage: null })}
                          onMouseEnter={() => this.setState({ currentImage: product[2][0].name.replace('/storage/public', '/storage') })}
                        >
                          <img src={product[2][0].name.replace('/storage/public', '/storage')} />
                        </div>
                        <div
                          className="carousel-image"
                          onMouseLeave={() => this.setState({ currentImage: null })}
                          onMouseEnter={() => this.setState({ currentImage: product[2][1].name.replace('/storage/public', '/storage') })}
                        >
                          <img src={product[2][1].name.replace('/storage/public', '/storage')} />
                        </div>
                      </div>
                      <div className="carousel-bigImage">
                        <img src={currentImage} />
                      </div>
                    </>
                  )}
                  {product[2].length > 2 && (
                    <>
                      <Carousel
                        arrows
                        dots
                        infinite
                        slidesPerPage={2}
                        itemWidth={100}
                      >
                        {product[2].map((image) => (
                          <>
                            <div
                              className="carousel-image"
                              onMouseLeave={() => this.setState({ currentImage: null })}
                              onMouseEnter={() => this.setState({ currentImage: image.name.replace('/storage/public', '/storage') })}
                            >
                              <img src={image.name.replace('/storage/public', '/storage')} />
                            </div>
                          </>
                        ))}
                      </Carousel>
                      {currentImage
                      && (
                      <div className="carousel-bigImage">
                        <img src={currentImage} />
                      </div>
                      )}
                    </>
                  )}
                </div>
              );
            }
            return <Chip label="No images" key={dataIndex} />;
          }
          // const value = productsListStorage.getProducts[dataIndex][0];
          // if (!value || value.length == 0) return null;
          // return value.map((val, key) => <Chip label={1} key={key} />);
          ,
        },
      },

      {
        name: 'Brand',
        options:
          {
            filter: true,
            filterType: 'multiselect',
            customFilterListOptions: { render: (v) => `Brand: ${v}` },
            sort: true,
          },
      },
      {
        name: 'Article',
        options:
          {
            filter: true,
            filterType: 'textField',
            // customBodyRenderLite: (dataIndex) => {
            //   const val = productsListStorage.getProducts[dataIndex][3];
            //   return val;
            // },
            sort: true,
          },
      },
      {
        name: 'Color',
        options:
          {
            filter: true,
            filterType: 'textField',
            sort: true,
          },
      },
      {
        name: 'Sizes',
        options:
          {
            filter: true,
            // filterType: 'multiselect',
            filterType: 'textField',
            sort: true,
          },
      },
      {
        name: 'inStock',
        options:
          {
            filter: true,
            // filterType: 'multiselect',
            filterType: 'textField',
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const index = dataIndex;
              return <EditableInStok index={dataIndex} />;
            },
          },
      },
      {
        name: 'Price',
        options:
          {
            filter: true,
            // filterType: 'multiselect',
            filterType: 'textField',
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const index = dataIndex;
              return <EditablePrice index={dataIndex} />;
            },
          },
      },
      {
        name: 'Qty',
        options:
          {
            filter: false,
            // filterType: 'multiselect',
            // filterType: 'textField',
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const { inPackage } = productsListStorage.products[dataIndex];
              return inPackage;
            },
          },
      },
      {
        name: 'Order',
        options:
          {
            filter: false,
            // filterType: 'multiselect',
            // filterType: 'textField',
            sort: true,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
              const index = dataIndex;
              return <OrderQty dataIndex={dataIndex} />;
            },
          },
      },
      {
        name: 'Amount',
        options:
          {
            filter: false,
            // filterType: 'multiselect',
            // filterType: 'textField',
            sort: true,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
              const index = dataIndex;
              const { id, inPackage } = productsListStorage.products[dataIndex];
              return <OrderAmount id={id} inPackage={inPackage} />;
            },
          },
      },
      {
        name: 'Total',
        options:
          {
            filter: false,
            // filterType: 'multiselect',
            // filterType: 'textField',
            sort: true,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
              const index = dataIndex;
              const { id, inPackage, price } = productsListStorage.products[dataIndex];
              return <OrderTotal id={id} inPackage={inPackage} price={price} />;
            },
          },
      },
      {
        name: 'Actions',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => (
            <div className="flex-in-row action-in-table">
              <Button
                onClick={() => {
                  const product = productsListStorage.products[dataIndex];
                  history.push(`/application/edit/${product.brand}/${product.article}/${product.id}`);
                }}
                variant="contained"
                color="primary"
              >
                Edit item
              </Button>
              <Button
                onClick={() => {
                  const product = productsListStorage.products[dataIndex];
                  history.push(`/application/edit/${product.brand}/${product.article}`);
                }}
                variant="contained"
                color="secondary"
              >
                Edit article
              </Button>
            </div>
          ),
        },
      },
      {
        name: 'comment',
        options:
          {
            filter: false,
            // filterType: 'multiselect',
            // filterType: 'textField',
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const index = dataIndex;
              return productsListStorage.products[dataIndex].comment;
            },
          },
      },
      {
        name: 'barcode',
        options:
          {
            filter: false,
            // filterType: 'multiselect',
            // filterType: 'textField',
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const index = dataIndex;
              return productsListStorage.products[dataIndex].barcode;
            },
          },
      },
    ];
    const options = {
      onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
      // changedColumn: string, filterList: array, type: enum('checkbox', 'dropdown', 'multiselect', 'textField', 'custom', 'chip', 'reset'), changedColumnIndex, displayData
        console.log('FILTER CHANGE: ', changedColumn, filterList, type, changedColumnIndex, displayData);
      },
      onRowsDelete: this.onDelete,
      downloadOptions: { separator: ';' },
      onDownload: (buildHead, buildBody, columns, data) => {
        console.log(columns, data);
        return `\uFEFF${buildHead(columns)}${buildBody(data)}`;
      },
    };
    return (
      <Widget className="productsTable">
        {/* ProductsList */}
        {/* {' '} */}
        {/* {productsListStorage.products.length} */}
        <div style={{
          display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', borderBottom: '1px solid lightgray', marginBottom: 10,
        }}
        >
          Order total:
          {' '}
          $
          {' '}
          <OrderSumm />
          <Button onClick={localBrowserStorage.clear}><i className="fa fa-2x fa-times" style={{ color: 'red' }} /></Button>
          <Button onClick={() => history.push('/application/invoice')}>Invoice</Button>
        </div>
        <div className="flex flex-row">
          <div className="width-3-4 pspace">
            <MUIDataTable
              title="Products"
              data={productsListStorage.getProducts}
              columns={columns}
              options={options}
            />
          </div>
          <div className="width-1-4 pspace fullheight">
            <OrganizationOrders />
          </div>
        </div>
      </Widget>
    );
  }
}

ProductsList.propTypes = {};

export default withRouter(observer(ProductsList));
