import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Progress, Alert } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { dismissAlert } from '../../actions/alerts';
import s from './Sidebar.module.scss';
import LinksGroup from './LinksGroup';

import { changeActiveSidebarItem } from '../../actions/navigation';
import { logoutUser } from '../../actions/user';
import HomeIcon from '../Icons/SidebarIcons/HomeIcon.jsx';
import TypographyIcon from '../Icons/SidebarIcons/TypographyIcon.jsx';
import TablesIcon from '../Icons/SidebarIcons/TablesIcon.jsx';
import NotificationsIcon from '../Icons/SidebarIcons/NotificationsIcon.jsx';
import ComponentsIcon from '../Icons/SidebarIcons/ComponentsIcon.jsx';
import layoutStorage from '../../storages/layoutStorage';
import alertsStorage from '../../storages/alertsStorage';

class Sidebar extends React.Component {
    static propTypes = {
      sidebarStatic: PropTypes.bool,
      sidebarOpened: PropTypes.bool,
      dispatch: PropTypes.func.isRequired,
      activeItem: PropTypes.string,
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }).isRequired,
    };

    static defaultProps = {
      sidebarStatic: false,
      activeItem: '',
    };

    constructor(props) {
      super(props);

      this.doLogout = this.doLogout.bind(this);
    }

    componentDidMount() {
      this.element.addEventListener('transitionend', () => {
        if (layoutStorage.sidebarOpened) {
          this.element.classList.add(s.sidebarOpen);
        }
      }, false);
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.sidebarOpened !== this.props.sidebarOpened) {
        if (nextProps.sidebarOpened) {
          this.element.style.height = `${this.element.scrollHeight}px`;
        } else {
          this.element.classList.remove(s.sidebarOpen);
          setTimeout(() => {
            this.element.style.height = '';
          }, 0);
        }
      }
    }

    dismissAlert(id) {
      this.props.dispatch(dismissAlert(id));
    }

    doLogout() {
      this.props.dispatch(logoutUser());
    }

    render() {
      return (
        <nav
          className={cx(s.root)}
          ref={(nav) => {
            this.element = nav;
          }}
        >
          <header className={s.logo}>
            <a href="https://demo.flatlogic.com/light-blue-react/">
              Light
              <span
                className="fw-bold"
              >
                Blue
              </span>
            </a>
          </header>
          <ul className={s.nav}>
            <LinksGroup
              onActiveSidebarItemChange={layoutStorage.setActiveItem}
              activeItem={layoutStorage.activeItem}
              header="Products"
              isHeader
              iconName={<HomeIcon className={s.menuIcon} />}
              link="/application/products"
              index="products"
              childrenLinks={[
                {
                  header: 'Upload', link: '/application/upload',
                },
                {
                  header: 'Add', link: '/application/add',
                },
                {
                  header: 'List', link: '/application/list',
                },
              ]}
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) => layoutStorage.setActiveItem(activeItem)}
              activeItem={layoutStorage.activeItem}
              header="Profile"
              isHeader
              iconName={<HomeIcon className={s.menuIcon} />}
              link="/application/profile"
              index="profile"
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) => layoutStorage.setActiveItem(activeItem)}
              activeItem={layoutStorage.activeItem}
              header="Catalog"
              isHeader
              iconName={<HomeIcon className={s.menuIcon} />}
              link="/shop"
              index="catalog"
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) => layoutStorage.setActiveItem(activeItem)}
              activeItem={layoutStorage.activeItem}
              header="Cart"
              isHeader
              iconName={<HomeIcon className={s.menuIcon} />}
              link="/application/cart"
              index="cart"
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) => layoutStorage.setActiveItem(activeItem)}
              activeItem={layoutStorage.activeItem}
              header="Orders"
              isHeader
              iconName={<HomeIcon className={s.menuIcon} />}
              link="/application/orders"
              index="orders"
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) => layoutStorage.setActiveItem(activeItem)}
              activeItem={layoutStorage.activeItem}
              header="Settings"
              isHeader
              iconName={<HomeIcon className={s.menuIcon} />}
              link="/application/settings"
              index="settings"
            />

            <LinksGroup
              onActiveSidebarItemChange={(activeItem) => layoutStorage.setActiveItem(activeItem)}
              activeItem={layoutStorage.activeItem}
              header="Dashboard"
              isHeader
              iconName={<HomeIcon className={s.menuIcon} />}
              link="/application/main/dashboard"
              index="main"
            />
            <h5 className={[s.navTitle, s.groupTitle].join(' ')}>TEMPLATE</h5>
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) => layoutStorage.setActiveItem(activeItem)}
              activeItem={layoutStorage.activeItem}
              header="Typography"
              isHeader
              iconName={<TypographyIcon className={s.menuIcon} />}
              link="/application/typography"
              index="core"
            />
            <LinksGroup
              onActiveSidebarItemChange={(t) => this.props.dispatch(changeActiveSidebarItem(t))}
              activeItem={layoutStorage.activeItem}
              header="Tables Basic"
              isHeader
              iconName={<TablesIcon className={s.menuIcon} />}
              link="/application/tables"
              index="tables"
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) => layoutStorage.setActiveItem(activeItem)}
              activeItem={layoutStorage.activeItem}
              header="Notifications"
              isHeader
              iconName={<NotificationsIcon className={s.menuIcon} />}
              link="/application/notifications"
              index="ui"
            />
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) => layoutStorage.setActiveItem(activeItem)}
              activeItem={layoutStorage.activeItem}
              header="Components"
              isHeader
              iconName={<ComponentsIcon className={s.menuIcon} />}
              link="/application/components"
              index="components"
              childrenLinks={[
                {
                  header: 'Charts', link: '/application/components/charts',
                },
                {
                  header: 'Icons', link: '/application/components/icons',
                },
                {
                  header: 'Maps', link: '/application/components/maps',
                },
              ]}
            />
          </ul>
          <h5 className={s.navTitle}>
            LABELS
            {/* eslint-disable-next-line */}
                </h5>
          {/* eslint-disable */}
                <ul className={s.sidebarLabels}>
                    <li>
                        <a href="#">
                            <i className="fa fa-circle text-success mr-2"/>
                            <span className={s.labelName}>My Recent</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i className="fa fa-circle text-primary mr-2"/>
                            <span className={s.labelName}>Starred</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i className="fa fa-circle text-danger mr-2"/>
                            <span className={s.labelName}>Background</span>
                        </a>
                    </li>
                </ul>
                {/* eslint-enable */}
          <h5 className={s.navTitle}>
            PROJECTS
          </h5>
          <div className={s.sidebarAlerts}>
            {alertsStorage.alertsList.map((alert) => (
              <Alert
                key={alert.id}
                className={s.sidebarAlert}
                color="transparent"
                            isOpen={true} // eslint-disable-line
                toggle={() => {
                  this.dismissAlert(alert.id);
                }}
              >
                <span>{alert.title}</span>
                <br />
                <Progress
                  className="bg-subtle-blue progress-xs mt-1"
                  color={alert.color}
                  value={alert.value}
                />
                <span className={s.alertFooter}>{alert.footer}</span>
              </Alert>
            ))}
          </div>
        </nav>
      );
    }
}

export default withRouter(observer(Sidebar));
