import React, { Component } from 'react';
import PropTypes from 'prop-types';

class EmptyPage extends Component {
  render() {
    return (
      <div>
        EmptyPage
      </div>
    );
  }
}

EmptyPage.propTypes = {};

export default EmptyPage;
