import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import localBrowserStorage from '../../shared/storages/LocalBrowserStorage';
import productsListStorage from '../../shared/storages/ProductsListStorage';

class OrderSumm extends Component {
  render() {
    let summ = 0;
    Object
      .keys(localBrowserStorage.products)
      .map((id) => {
        const product = productsListStorage.products.find((item) => item.id == id);
        if (product) {
          const res = localBrowserStorage.products[id].qty
            * product.inPackage
            * product.price;
          summ += +res;
        }
      });
    return (summ / 100).toFixed(2);
  }
}

OrderSumm.propTypes = {};

export default observer(OrderSumm);
