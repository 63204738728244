/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

class TextInput extends Component {
  onInput = (e) => {
    const { onInput } = this.props;
    onInput(e);
  };

  render() {
    const {
      type,
      id,
      label,
      placeholder,
      value,
      onFocus,
      onBlur,
      disabled,
      onKeyPress,
      step,
      pattern,
      className,
    } = this.props;
    return (
      <div style={{ width: '100%' }}>
        {label && <label htmlFor={id} className="form-label">{label}</label>}
        <input
          disabled={disabled}
          autoComplete="off"
          onFocus={onFocus}
          onBlur={onBlur}
          type={type}
          className={`form-control ${className || ''}`}
          id={id}
          placeholder={placeholder}
          onInput={this.onInput}
          value={value}
          onKeyPress={onKeyPress}
          step={step}
          pattern={pattern}
        />
      </div>
    );
  }
}

TextInput.defaultProps = {
  type: 'text',
  // eslint-disable-next-line react/default-props-match-prop-types
  id: null,
  label: null,
  placeholder: '',
  onInput: () => {},
  onFocus: () => {},
  // eslint-disable-next-line react/default-props-match-prop-types
  value: '',
  onBlur: () => {},
  disabled: false,
  onKeyPress: () => {},
  step: null,
  pattern: null,
  className: null,
};
TextInput.propTypes = {
  type: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onInput: PropTypes.func,
  onFocus: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.func,
  step: PropTypes.any,
  pattern: PropTypes.string,
  className: PropTypes.string,
};

export default observer(TextInput);
