import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Invoice.scss';
import { observer } from 'mobx-react';
import { translate } from 'react-switch-lang';
import IbSpan from '../components/invoices/IbSpan';
import currentUserStorage from '../../shared/storages/CurrentUserStorage';
import productsListStorage from '../../shared/storages/ProductsListStorage';
import organizationOrderStorage from '../containers/OrganizationOrders/OrganizationOrderStorage';

class Invoice extends Component {
  componentDidMount() {
    if (productsListStorage.products.length == 0) {
      productsListStorage.loadProducts();
    }
    if (organizationOrderStorage.items.length == 0) {
      organizationOrderStorage.loadCurrentOrder();
    }
  }

  render() {
    let total = 0;
    const dateTo = new Date();
    dateTo.setDate(dateTo.getDate() + 3);
    const { t } = this.props;
    const {
      id,
      organization_id,
      items,
      name,
      surname,
      town,
      phone,
      email,
      addr,
      cargo,
      cargoCode,
    } = organizationOrderStorage;

    const {
      companyName,
      companyEmail,
      companyPhone,
      companyZip,
      companyAddr,
      www,
      payerPerson,
      currency,
      comment,
      bankAcc,
      bankIBAN,
      bankSwift,
      thanksFooter,
    } = currentUserStorage.forInvoice;

    return (
      <div id="invoice-container">
        <section id="memo">
          <div className="logo">
            <img
              src="https://opt.moda/images/logo.png"
            />
          </div>

          <div className="company-info">
            <div
              className="ibcl_company_name ib_stop_animate"
            >
              {companyName}
            </div>

            <br />

            <span
              className="ibcl_company_address ib_stop_animate"
            >
              {companyAddr}
            </span>
            <span
              className="ibcl_company_city_zip_state ib_stop_animate"
            >
              {' '}
              ●
              {' '}
              {companyZip}
            </span>

            <br />

            <span
              className="ibcl_company_phone_fax ib_stop_animate"
            >
              {companyPhone}
            </span>
            <span
              className="ibcl_company_email_web ib_stop_animate"
            >
              {' '}
              ●
              {' '}
              {www}
              {' '}
              ●
              {' '}
              {companyEmail}
            </span>
          </div>

        </section>

        <section id="invoice-title-number">

          <span
            id="title"
            className="ibcl_invoice_title ib_stop_animate"
          >
            {t('invoice.INVOICE')}
          </span>
          <span
            id="number"
            data-ibcl-id="invoice_number"
            className="ibcl_invoice_number ib_stop_animate"
            data-tooltip="tooltip"
            data-placement="top"
            title=""
            contentEditable="true"
            data-original-title="Enter invoice number"
          >
            #
            {id.toString().padStart(10, '0')}
          </span>

        </section>

        <div className="clearfix" />

        <section id="client-info">
          <span
            data-ibcl-id="bill_to_label"
            className="ibcl_bill_to_label ib_stop_animate"
            data-tooltip="tooltip"
            data-placement="top"
            title="Enter bill to label"
            contentEditable="true"
          >
            {t('invoice.To')}
            :
          </span>
          <div>
            <span
              className="bold ibcl_client_name ib_stop_animate"
              data-ibcl-id="client_name"
              data-tooltip="tooltip"
              data-placement="top"
              title="Enter client name"
              contentEditable="true"
            >
              {name}
              {' '}
              {surname}
            </span>
          </div>

          <div>
            <span
              data-ibcl-id="client_address"
              className="ibcl_client_address ib_stop_animate"
              data-tooltip="tooltip"
              data-placement="top"
              title="Enter client address"
              contentEditable="true"
            >
              {town}
            </span>
          </div>

          <div>
            <span
              data-ibcl-id="client_city_zip_state"
              className="ibcl_client_city_zip_state ib_stop_animate"
              data-tooltip="tooltip"
              data-placement="top"
              title="Enter client city, zip, country"
              contentEditable="true"
            >
              {addr}
            </span>
          </div>

          <div>
            <span
              data-ibcl-id="client_phone_fax"
              className="ibcl_client_phone_fax ib_stop_animate"
              data-tooltip="tooltip"
              data-placement="top"
              title="Enter client pnone &amp; fax"
              contentEditable="true"
            >
              {phone}
            </span>
          </div>

          <div>
            <span
              className="ibcl_client_email ib_stop_animate"
            >
              {email}
            </span>
          </div>

          <div>
            <span
              className="ibcl_client_other ib_stop_animate"
            >
              {t('invoice.Attn')}
              :
              {' '}
              {payerPerson}
            </span>
          </div>
        </section>

        <div className="clearfix" />

        <section id="items">

          <table cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                <th
                  className="ibcl_item_row_number_label"
                />
                <th
                  className="ibcl_item_description_label ib_stop_animate"
                >
                  {t('invoice.Image')}
                </th>
                <th
                  className="ibcl_item_description_label ib_stop_animate"
                >
                  {t('invoice.Brand')}
                </th>
                <th
                  className="ibcl_item_description_label ib_stop_animate"
                >
                  {t('invoice.Article')}
                </th>
                <th
                  className="ibcl_item_description_label ib_stop_animate"
                >
                  {t('invoice.Color')}
                </th>
                <th
                  className="ibcl_item_description_label ib_stop_animate"
                >
                  {t('invoice.Sizes')}
                </th>
                <th
                  className="ibcl_item_quantity_label ib_stop_animate"
                >
                  {t('invoice.Pcs')}
                </th>
                <th
                  className="ibcl_item_price_label ib_stop_animate"
                >
                  {t('invoice.In package')}
                </th>
                <th
                  className="ibcl_item_price_label ib_stop_animate"
                >
                  {t('invoice.Price')}
                </th>
                <th
                  className="ibcl_item_price_label ib_stop_animate"
                >
                  {t('invoice.Quantity')}
                </th>
                <th
                  className="ibcl_item_line_total_label ib_stop_animate"
                >
                  {t('invoice.Ln Total')}
                </th>
              </tr>
            </thead>
            <tbody>
              {items && items.map((item, index) => {
                if (!item || !item.id || !item.qty) return null;
                const product = productsListStorage.products.find((p) => p.id == item.id);
                if (!product) return null;
                const lnTotal = item.qty * product.price * product.inPackage / 100;
                total += item.qty * product.price * product.inPackage;
                return (
                  <tr data-iterate="item">
                    <td
                      data-ibcl-id="item_row_number"
                      className="ibcl_item_row_number"
                      data-tooltip="tooltip"
                      data-placement="top"
                      title=""
                      style={{ position: 'relative' }}
                    >
                      <IbSpan data-row-number="item_row_number">{index + 1}</IbSpan>
                    </td>
                    <td
                      className="ibcl_item_description ib_stop_animate"
                    >
                      <div className="invoice-itemPhoto">
                        <img width={100} height={100} src={product.images && product.images.length > 0 ? product.images[0].thumbnail : 'photo'} />
                      </div>
                    </td>
                    <td>
                      {product.brand}
                    </td>
                    <td>
                      {product.article}
                    </td>
                    <td>
                      {product.color}
                    </td>
                    <td>
                      {product.sizes}
                    </td>
                    <td
                      className="ibcl_item_quantity ib_stop_animate"
                    >
                      {item.qty}
                    </td>
                    <td
                      className="ibcl_item_quantity ib_stop_animate"
                    >
                      {product.inPackage}
                    </td>
                    <td
                      className="ibcl_item_price add_currency_left ib_stop_animate"
                    >
                      {product.price / 100}
                    </td>
                    <td
                      className="ibcl_item_price add_currency_left ib_stop_animate"
                    >
                      {product.inPackage * item.qty}
                    </td>
                    <td
                      className="ibcl_item_line_total add_currency_left"
                    >
                      {lnTotal}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

        </section>

        <section id="sums">

          <table cellPadding="0" cellSpacing="0">
            <tbody>
              {/* <tr> */}
              {/*  <th */}
              {/*    className="ibcl_amount_subtotal_label ib_stop_animate" */}
              {/*  > */}
              {/*    {t('invoice.Subtotal')} */}
              {/*    : */}
              {/*  </th> */}
              {/*  <td */}
              {/*    className="ibcl_amount_subtotal" */}
              {/*  > */}
              {/*    $ */}
              {/*    {total / 100} */}
              {/*  </td> */}
              {/* </tr> */}

              {/* <tr data-iterate="tax" style={{ display: 'table-row' }}> */}
              {/*  <th */}
              {/*    data-ibcl-id="tax_name" */}
              {/*    className="ibcl_tax_name ib_stop_animate" */}
              {/*    data-tooltip="tooltip" */}
              {/*    data-placement="top" */}
              {/*    title="Enter tax label" */}
              {/*    contentEditable="true" */}
              {/*  > */}
              {/*    Tax 1: */}
              {/*  </th> */}
              {/*  <td */}
              {/*    data-ibcl-id="tax_value" */}
              {/*    className="ibcl_tax_value" */}
              {/*    data-tooltip="tooltip" */}
              {/*    data-placement="top" */}
              {/*    title="" */}
              {/*    data-ib-value="2" */}
              {/*    data-original-title="" */}
              {/*  > */}
              {/*    $0.00 */}
              {/*  </td> */}
              {/* </tr> */}
              {/* <tr data-iterate="tax" style={{ display: 'table-row' }}> */}
              {/*  <th */}
              {/*    data-ibcl-id="tax_name" */}
              {/*    className="ibcl_tax_name ib_stop_animate" */}
              {/*    data-tooltip="tooltip" */}
              {/*    data-placement="top" */}
              {/*    title="Enter tax label" */}
              {/*    contentEditable="true" */}
              {/*  > */}
              {/*    Tax 2: */}
              {/*  </th> */}
              {/*  <td */}
              {/*    data-ibcl-id="tax_value" */}
              {/*    className="ibcl_tax_value" */}
              {/*    data-tooltip="tooltip" */}
              {/*    data-placement="top" */}
              {/*    title="" */}
              {/*    data-ib-value="5" */}
              {/*  > */}
              {/*    $0.00 */}
              {/*  </td> */}
              {/* </tr> */}
              {/* <tr data-iterate="tax"> */}
              {/*  <th */}
              {/*    data-ibcl-id="tax_name" */}
              {/*    className="ibcl_tax_name ib_stop_animate" */}
              {/*    data-tooltip="tooltip" */}
              {/*    data-placement="top" */}
              {/*    title="Enter tax label" */}
              {/*    contentEditable="true" */}
              {/*  > */}
              {/*    Tax: */}
              {/*  </th> */}
              {/*  <td */}
              {/*    data-ibcl-id="tax_value" */}
              {/*    className="ibcl_tax_value" */}
              {/*    data-tooltip="tooltip" */}
              {/*    data-placement="top" */}
              {/*    title="" */}
              {/*  > */}
              {/*    $0.00 */}
              {/*  </td> */}
              {/* </tr> */}

              <tr className="amount-total">
                <th
                  className="ibcl_amount_total_label ib_stop_animate"
                >
                  {t('invoice.Total')}
                  :
                </th>
                <td
                  className="ibcl_amount_total"
                >
                  $
                  {total / 100}
                </td>
              </tr>

              {/* <tr data-hide-on-quote="true"> */}
              {/*  <th */}
              {/*    className="ibcl_amount_paid_label ib_stop_animate" */}
              {/*  > */}
              {/*    {t('invoice.Paid')} */}
              {/*    : */}
              {/*  </th> */}
              {/*  <td */}
              {/*    className="ibcl_amount_paid add_currency_left ib_stop_animate" */}
              {/*  > */}
              {/*    0.00 */}
              {/*  </td> */}
              {/* </tr> */}

              {/* <tr data-hide-on-quote="true"> */}
              {/*  <th */}
              {/*    className="ibcl_amount_due_label ib_stop_animate" */}
              {/*  > */}
              {/*    {t('invoice.Amount Due')} */}
              {/*    : */}
              {/*  </th> */}
              {/*  <td */}
              {/*    className="ibcl_amount_due" */}
              {/*  > */}
              {/*    $ */}
              {/*    {total / 100} */}
              {/*  </td> */}
              {/* </tr> */}

            </tbody>
          </table>

          <div className="clearfix" />

        </section>

        <div className="clearfix" />

        <section id="invoice-info">
          <div>
            <span
              className="ibcl_issue_date_label ib_stop_animate"
            >
              {t('invoice.Issued on')}
              :
            </span>
            <span
              className="ibcl_issue_date ib_stop_animate"
            >
              {(new Date()).toLocaleDateString()}
            </span>
          </div>
          <div>
            <span
              className="ibcl_due_date_label ib_stop_animate"
            >
              {t('invoice.Due on')}
              :
            </span>
            {' '}
            <span
              className="ibcl_due_date ib_stop_animate"
            >
              {dateTo.toLocaleDateString()}
            </span>
          </div>

          <br />

          <div>
            <span
              className="ibcl_currency_label ib_stop_animate"
            >
              {t('invoice.Currency')}
              :
            </span>
            <span
              className="ibcl_currency ib_stop_animate"
            >
              {currency}
            </span>
          </div>
        </section>

        <section id="terms">

          <div
            className="notes ibcl_terms ib_stop_animate"
            dangerouslySetInnerHTML={{ __html: comment }}
          />

          <br />

          <div className="payment-info">
            <div
              className="ibcl_payment_info1 ib_stop_animate"
            >
              {t('invoice.Payment details')}
              :
            </div>
            <div
              className="ibcl_payment_info2 ib_stop_animate"
            >
              {t('invoice.ACC')}
              {' '}
              {bankAcc}
            </div>
            <div
              className="ibcl_payment_info3 ib_stop_animate"
            >
              {t('invoice.IBAN')}
              :&nbsp;
              {bankIBAN}
            </div>
            <div
              className="ibcl_payment_info4 ib_stop_animate"
            >
              {t('invoice.SWIFT')}
              {' '}
              {bankSwift}
            </div>
            <div
              className="ibcl_payment_info5 ib_stop_animate"
            />
          </div>

        </section>

        <div className="clearfix" />

        <div
          className="thank-you ibcl_terms_label ib_stop_animate"
        >
          {t('invoice.thanksFooter')}
        </div>

        <div className="clearfix" />
      </div>
    );
  }
}

Invoice.propTypes = {};

export default translate(observer(Invoice));
