import React from 'react';
import { Button } from '@material-ui/core';

function FullscreenDialog({ dialogText, onClose, textOnClose }) {
  return (
    <div className="fullscreen-dialog">
      <div dangerouslySetInnerHTML={{ __html: dialogText }} />
      <Button variant="contained" color="primary" onClick={onClose}>{textOnClose}</Button>
    </div>
  );
}

FullscreenDialog.propTypes = {};

export default FullscreenDialog;
