import React from 'react';
import SunEditor from 'suneditor-react';
import { Tab, Tabs } from '@material-ui/core';
import { translate } from 'react-switch-lang';
import TabPanel from '../../shared/components/TabPanel';
import a11yProps from '../../shared/helpers/a11yProps';
import { saveTranslate } from '../../shared/translations';

function Editor({ t }) {
  const [valueEn, setValueEn] = React.useState(t('dialog1', {}, 'en'));
  const [valueRu, setValueRu] = React.useState(t('dialog1', {}, 'ru'));
  const [valueTr, setValueTr] = React.useState(t('dialog1', {}, 'tr'));
  const [valueUk, setValueUk] = React.useState(t('dialog1', {}, 'uk'));
  const [tabIndex, setTabIndex] = React.useState(0);
  return (
    <div>
      <Tabs
        value={tabIndex}
        onChange={(event, index) => setTabIndex(index)}
        aria-label="simple tabs example"
        wrapped
        scrollButtons="auto"
        variant="scrollable"
      >
        <Tab label="En" {...a11yProps(0)} />
        <Tab label="Ru" {...a11yProps(1)} />
        <Tab label="Tr" {...a11yProps(2)} />
        <Tab label="UA" {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <SunEditor defaultValue={valueEn} onChange={setValueEn} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <SunEditor defaultValue={valueRu} onChange={setValueRu} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <SunEditor defaultValue={valueTr} onChange={setValueTr} />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <SunEditor defaultValue={valueUk} onChange={setValueUk} />
      </TabPanel>
      <button onClick={() => saveTranslate('dialog1', {
        en: valueEn, ru: valueRu, uk: valueUk, tr: valueTr,
      })}
      >
        {t('Save translations')}
      </button>
    </div>
  );
}

Editor.propTypes = {};

export default translate(Editor);
