/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import { observer } from 'mobx-react';
import { Paper } from '@material-ui/core';
import { requestPost } from '../../../shared/real.backend';
import settingsStorage from '../../../shared/storages/SettingsStorage';
import './Cart.scss';
import OrderStatus from '../../components/OrderStatus';

class SuccessOrderPage extends Component {
  constructor(props) {
    super(props);
    const { match } = props;
    const orderID = match?.params?.id;
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      order: {
        id: orderID,
        orderItems: [],
      },
      widget: null,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const orderID = match?.params?.id;

    requestPost('/api/orders/detail', {
      order_id: orderID,
    })
      .then((response) => {
        if (response?.status === 'success') {
          this.setState({
            order: response.order,
            widget: response.widget,
          });
        }
      });
  }

  render() {
    const {
      order: {
        id,
        total, status,
      },
    } = this.state;
    const { widget } = this.state;
    const { t } = this.props;
    const { prepaymentPercent } = settingsStorage;
    return (
      <div className="order-page__container order-page__success">
        <Paper className="order-page__success-container">
          <div className="flex-center flex-between">
            <div>
              #
              {id.toString().padStart(12, '0')}
            </div>
            <div>
              <OrderStatus status={status} className="flex-center" />
            </div>
          </div>
          <div className="flex-between mt-1">
            <div className="flex-left">
              {t('Order total')}
              :
            </div>
            <div className="flex-right">
              $
              {(total / 100).toFixed(2)}
            </div>
          </div>
          <div className="flex-between mt-1">
            <div className="flex-left">
              {t('Delivery total')}
              :
            </div>
            <div className="flex-right">
              $5/kg + 7%
            </div>
          </div>
          <div className="flex-between mt-2">
            <div className="flex-left">
              {t('Prepayment')}
              :
            </div>
            <div className="flex-right">
              20% =
              {' $'}
              {((total * prepaymentPercent) / 10000).toFixed(2)}
            </div>
          </div>
          <br />
          {status < 2 && (
          <div className="flex-center">
            <div className="order-liqpay" dangerouslySetInnerHTML={{ __html: widget }} />
          </div>
          )}
        </Paper>
      </div>
    );
  }
}

SuccessOrderPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(withRouter(observer(SuccessOrderPage)));
