/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import currentProduct from '../../shared/storages/CurrentProduct';

class TextInputSelectorOrganizations extends Component {
  onInput = (e) => {
    const { onInput } = this.props;
    if (onInput) {
      onInput(e);
    } else {
      currentProduct.changeField('brand', e.target.value);
    }
  };

  render() {
    const {
      id, label, value, disabled, options,
    } = this.props;
    return (
      <div className="mb-3">
        {label && <label htmlFor={id} className="form-label">{label}</label>}
        <select
          disabled={disabled}
          className="form-control"
          value={value}
          onChange={this.onInput}
        >
          <option value={null}>Select Organization</option>
          {!options
          && currentProduct
            .brandsList
            .map((brand) => <option value={brand}>{brand?.toUpperCase()}</option>)}
          {options && options.map((brand) => <option value={brand}>{brand.toUpperCase()}</option>)}
        </select>
        {/* <BrandsSelector /> */}
      </div>
    );
  }
}

TextInputSelectorOrganizations.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  id: null,
  label: null,
  onInput: () => {},
  // eslint-disable-next-line react/default-props-match-prop-types
  value: '',
  disabled: false,
  options: [],
};
TextInputSelectorOrganizations.propTypes = {
  // eslint-disable-next-line react/require-default-props
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onInput: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array,
};

export default observer(TextInputSelectorOrganizations);
