import React, { useState } from 'react';
import { Container, Drawer } from '@material-ui/core';
import { Route, useLocation } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import { observer, useObserver } from 'mobx-react';
import Hammer from 'rc-hammerjs';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Header2 from './containers/Header';
import currentUserStorage from '../shared/storages/CurrentUserStorage';
import UploadProductsFile from './pages/UploadProductsFile';
import AddProduct from './pages/AddProduct';
import ProductsList from './pages/ProductsList';
import Profile from './pages/Profile';
import Invoice from './pages/Invoice';
import Catalog from './pages/catalog/Catalog';
import CartPage from './pages/cart/CartPage';
import OrdersPage from './pages/orders/OrdersPage';
import SimpleOrderPage from './pages/orders/SimpleOrderPage';
import SuccessOrderPage from './pages/cart/SuccessOrderPage';
import EmptyPage from './pages/EmptyPage';
import MenuButtons from './components/MenuButtons';
import Settings from './pages/Settings';
import Header from '../v2/components/Header';
import Sidebar from '../v2/components/Sidebar';
import BreadcrumbHistory from '../v2/components/BreadcrumbHistory';
import s from '../v2/components/Layout/Layout.module.scss';
import layoutStorage from '../v2/storages/layoutStorage';
import Dashboard from '../v2/pages/dashboard';
import UIIcons from '../v2/pages/components/icons';
import UINotifications from '../v2/pages/notifications';
import TablesStatic from '../v2/pages/tables/static';
import MapsGoogle from '../v2/pages/components/maps/google';
import CoreTypography from '../v2/pages/typography';
import Charts from '../v2/pages/components/charts/Charts';

function MainLayout({ sidebarPosition = 'left', sidebarVisibility = 'show' }) {
  const [chatOpen, setChatOpen] = useState(false);
  // const location = useLocation();
  // console.log('WREST', location);
  // return null;
  const handleSwipe = (e) => {
    if ('ontouchstart' in window) {
      if (e.direction === 4 && !chatOpen) {
        layoutStorage.openSidebar();
        return;
      }
      if (e.direction === 2 && layoutStorage.sidebarOpened) {
        layoutStorage.closeSidebar();
        return;
      }
      setChatOpen(e.direction === 2);
    }
  };

  return useObserver(() => (
    <div
      className={[
        s.root,
        `sidebar-${sidebarPosition}`,
        `sidebar-${sidebarVisibility}`,
      ].join(' ')}
    >
      <div className={s.wrap}>
        <Header />
        {/* <Chat chatOpen={this.state.chatOpen} /> */}
        {/* <Helper /> */}
        <Sidebar />
        <Hammer onSwipe={handleSwipe}>
          <main className={s.content}>
            <BreadcrumbHistory url={location.pathname} />
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={200}
              >
                {/* <Header openMainMenu={openMainMenu} /> */}
                {/* <div>&nbsp;</div> */}
                <Container maxWidth="xl">
                  {!currentUserStorage.isLoaded && <div>Loading...</div>}
                  {currentUserStorage.isLoaded && !currentUserStorage.isLogged && <div>Need login...</div>}
                  {currentUserStorage.isLoaded && currentUserStorage.isLogged && (
                    <div>
                      <Route exact path="/application/main/dashboard" component={Dashboard} />
                      <Route path="/application/components/icons" exact component={UIIcons} />
                      <Route path="/application/notifications" exact component={UINotifications} />
                      <Route path="/application/components/charts" exact component={Charts} />
                      <Route path="/application/tables" exact component={TablesStatic} />
                      <Route path="/application/components/maps" exact component={MapsGoogle} />
                      <Route path="/application/typography" exact component={CoreTypography} />

                      <Route exact path="/application/upload" component={UploadProductsFile} />
                      <Route exact path="/application/add" component={AddProduct} />
                      <Route exact path="/application/edit/:brand/:article/:colorID" component={() => <AddProduct editMode />} />
                      <Route exact path="/application/edit/:brand/:article" component={() => <AddProduct editMode />} />
                      <Route exact path="/application/list" component={ProductsList} />
                      <Route exact path="/application/settings" component={Settings} />
                      <Route exact path="/application/profile" component={Profile} />
                      <Route exact path="/application/invoice" component={Invoice} />
                      <Route exact path="/application/catalog" component={Catalog} />
                      <Route exact path="/application/cart" component={CartPage} />
                      <Route exact path="/application/orders" component={OrdersPage} />
                      <Route exact path="/application/client/order/:id/simple" component={SimpleOrderPage} />
                      <Route exact path="/application/client/order/:id/success" component={SuccessOrderPage} />
                      <Route exact path="/application/client/order/:id/payment" component={SuccessOrderPage} />
                      <Route exact path="/application/organization/orders" component={EmptyPage} />
                      {/* <CameraCapture /> */}
                      {/* <FileCapture /> */}
                    </div>
                  )}
                </Container>
                {/* <Drawer */}
                {/*  anchor="left" */}
                {/*  open={mainMenuOpened} */}
                {/*  onClose={closeMainMenu} */}
                {/*  style={{ minWidth: '250px' }} */}
                {/*  classes={{ */}
                {/*    root: 'mainMenu__root', */}
                {/*    paper: 'mainMenu__paper', */}
                {/*  }} */}
                {/* > */}
                {/*  <MenuButtons /> */}
                {/* </Drawer> */}
              </CSSTransition>
            </TransitionGroup>
          </main>
        </Hammer>
      </div>
    </div>
  ));
}

export default MainLayout;
