import React from 'react';
import { translate } from 'react-switch-lang';
import { Col, Row } from 'reactstrap';
import TextInputSelector from './TextInputSelector';
import TextInput from './TextInput';

function PairMapper({
  from, to, t, onChange,
}) {
  return (
    <Row
      className="mb-10"
      style={{
        borderBottom: '1px solid black',
        marginBottom: '5px',
        paddingBottom: '5px',
        alignItems: 'center',
      }}
    >
      <Col>{from}</Col>
      <Col>
        <TextInput
          id={`pairSelector-${from}`}
          label={null}
          value={to}
          disabled={false}
          options={[
            { name: 'brand', value: t('brand') },
            { name: 'article', value: t('article') },
            { name: 'color', value: t('color') },
            { name: 'sizes', value: t('sizes') },
            { name: 'price', value: t('price') },
            { name: 'attentionDate', value: t('attentionDate') },
            { name: 'comment', value: t('comment') },
            { name: 'inStock', value: t('inStock') },
            { name: 'inPackage', value: t('inPackage') },
            { name: 'name', value: t('name') },
          ]}
          onInput={(e) => onChange(from, e.target.value)}
          defaultOptionName={t('Ignore')}
          defaultOptionValue={null}
        />
      </Col>
    </Row>
  );
}

PairMapper.propTypes = {};

export default translate(PairMapper);
