import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Catalog from './pages/Catalog';

class Main extends Component {
  render() {
    return (
      <div id="shop" className="application">
        <BrowserRouter>
          <Switch>
            <Route exact path="/shop" component={Catalog} />
            <Route exact path="/shop/catalog" component={() => 'Catalog'} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

Main.propTypes = {};

export default Main;
