import { makeAutoObservable } from 'mobx';
import productsListStorage from './ProductsListStorage';
import organizationOrderStorage from '../../application/containers/OrganizationOrders/OrganizationOrderStorage';

class LocalBrowserStorage {
  products = {};

  get productsList() {
    return Object.keys(this.products).map((id) => {
      let item = productsListStorage.products.find((product) => product.id == id);
      if (!item) item = {};
      return ({
        item,
        cart: {
          qty: this.products[id] ? this.products[id].qty : 0,
        },
      });
    });
  }

  constructor() {
    const data = localStorage.getItem('zakaz_order');
    if (data) {
      this.products = JSON.parse(data);
    }
    makeAutoObservable(this);
  }

  changeQty = (id, qty) => {
    if (!this.products[id]) this.products[id] = { qty: 0 };
    this.products[id].qty = +qty >= 0 ? +qty : 0;
    localStorage.setItem('zakaz_order', JSON.stringify(this.products));
    organizationOrderStorage.setItem(id, qty);
  };

  clear = () => {
    this.products = {};
    localStorage.setItem('zakaz_order', '{}');
  };
}

const localBrowserStorage = new LocalBrowserStorage();

export default localBrowserStorage;
