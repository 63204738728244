import { makeAutoObservable } from 'mobx';

class SettingsStorage {
  prepaymentPercent = 20;

  constructor() {
    makeAutoObservable(this);
  }
}

const settingsStorage = new SettingsStorage();

export default settingsStorage;
