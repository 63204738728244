import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-switch-lang';
import './OrderDetailButton.scss';
import { NavLink } from 'react-router-dom';

class OrderDetailButton extends PureComponent {
  render() {
    const { id, t } = this.props;

    return <NavLink to={`/application/client/order/${id}/payment`} className="orders__detail-button">{t('Details')}</NavLink>;
  }
}

OrderDetailButton.propTypes = {
  id: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(OrderDetailButton);
