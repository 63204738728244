/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import NumberFormat from 'react-number-format';
import productsListStorage from '../../shared/storages/ProductsListStorage';
import { requestPost } from '../../shared/real.backend';

class EditablePrice extends Component {
  state = {
    edited: false,
    value: 0,
  };

  componentDidMount() {
    const { index } = this.props;
    this
      .setState({
        value: productsListStorage.products[index]
          ? productsListStorage.products[index].price
          : 0,
      });
  }

  toggleEdit = () => this.setState((state) => ({ edited: !state.edited }));

  changeValue = (value) => this.setState(() => ({ value: Math.floor(+value * 100) }));

  savePrice = () => {
    const { index } = this.props;
    const { value } = this.state;
    requestPost('/api/products/updatePrice', {
      id: productsListStorage.products[index].id,
      price: value,
    })
      .then(() => {
        productsListStorage.changePrice(index, value / 100);
        this.setState({ edited: false });
      });
  };

  render() {
    const { index } = this.props;
    const { edited, value } = this.state;
    return (
      <>
        <div
          onClick={this.toggleEdit}
        >
          {!edited && productsListStorage.products[index].price / 100}
        </div>
        {edited && (
          <div>
            <NumberFormat
              thousandSeparator
              prefix="$"
              value={value / 100}
              onValueChange={(values) => {
                this.changeValue(values.value);
              }}
            />
            <button type="button" onClick={this.savePrice}><i className="fa fa-save" /></button>
          </div>
        )}
      </>
    );
  }
}

EditablePrice.propTypes = {
  index: PropTypes.number.isRequired,
};

export default observer(EditablePrice);
