import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { requestPost } from '../real.backend';
import brandsStorage from './BrandsStorage';
import currentUserStorage from './CurrentUserStorage';

class OrganizationsStorage {
  organizationsList = [];

  currentOrganization = {
    id: null,
    organizationName: '',
  }

  mode = null;

  expandedList = [];

  get myOrganizationIndex() {
    const index = this.organizationsList.findIndex((org) => org.id == currentUserStorage.organization_id);
    return index;
  }

  get myOrganization() {
    const index = this.organizationsList.findIndex((org) => org.id == currentUserStorage.organization_id);
    if (index >= 0) {
      return this.organizationsList[index];
    }
    return { accounts: [] };
  }

  get organizations() {
    // return
  }

  constructor() {
    makeAutoObservable(this);
  }

  changeNewOrganizationName = (e) => {
    this.currentOrganization.organizationName = e.target.value;
  }

  loadOrganizations = (filter = null) => {
    requestPost('/api/organizations/all', { filter })
      .then((resp) => {
        runInAction(() => {
          if (resp && resp.organizations) {
            // this.organizationsList = [];
            this.organizationsList = resp.organizations;
          }
        });
      });
  }

  createOrganization = () => {
    this.currentOrganization = {
      id: null,
      organizationName: '',
    };
    this.toggleAddMode();
  }

  toggleAddMode = () => {
    this.mode = this.mode === null ? 'add' : null;
  }

  disableAddMode = () => {
    this.mode = null;
  }

  saveCurrentOrg = () => {
    requestPost('/api/organizations/save', this.currentOrganization)
      .then((resp) => {
        runInAction(() => {
          this.organizationsList = [
            ...this.organizationsList,
            resp.organization,
          ];
          this.currentOrganization = {
            id: null,
            organizationName: '',
          };
          this.disableAddMode();
        });
        toast.success('Added');
      });
  }

  addBrandToOrganization = (dataIndex, value) => {
    if (value === '' || !value) return FalsePromise();
    return requestPost('/api/organizations/addBrand', {
      id: this.organizationsList[dataIndex].id,
      brand: value,
    })
      .then((resp) => {
        runInAction(() => {
          const index = this.organizationsList[dataIndex].brands.findIndex((brand) => brand.id == resp.brand.id);
          if (index < 0) {
            this.organizationsList[dataIndex].brands = [
              ...this.organizationsList[dataIndex].brands,
              resp.brand,
            ];
            toast.success('Added');
          } else {
            toast.info('Already added');
          }
        });
        return true;
      });
  }

  addAccountToOrganization = (dataIndex, value, password, name) => requestPost('/api/organizations/addAccount', {
    organization_id: this.organizationsList[dataIndex].id,
    name,
    email: value,
    password,
  })
    .then((resp) => {
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status === 'success') {
        runInAction(() => {
          this.organizationsList[dataIndex].accounts = [
            ...this.organizationsList[dataIndex].accounts,
            resp.user,
          ];
        });
        return true;
      }
      return message || 'Network error';
    })

  removeOrganization(dataIndex) {
    return requestPost('/api/organization/remove', {
      organization_id: this.organizationsList[dataIndex].id,
    })
      .then((resp) => {
        const status = get(resp, 'status');
        const message = get(resp, 'message');
        if (status === 'success') {
          this.loadOrganizations();
          return true;
        }
        return message || 'Network error';
      });
  }

  removeBrandFromOrganization(dataIndex, brand) {
    console.log(dataIndex, brand);
    const org = this.organizationsList[dataIndex];
    if (org) {
      return requestPost('/api/organization/removeBrand', {
        organization_id: org.id,
        brand_id: brand.id,
      })
        .then((resp) => {
          const status = get(resp, 'status');
          const message = get(resp, 'message');
          if (status === 'success') {
            runInAction(() => {
              this.loadOrganizations();
              brandsStorage.loadBrands();
            });
            return true;
          }
          return message || 'Network error';
        });
    }
  }

  removeOrganizationBulk(indexes) {
    const ids = [];
    return Promise.all(indexes.map((dataIndex) => requestPost('/api/organization/remove', {
      organization_id: this.organizationsList[dataIndex].id,
    })
      .then((resp) => {
        const status = get(resp, 'status');
        const message = get(resp, 'message');
        if (status === 'success') {
          return true;
        }
        return message || 'Network error';
      })))
      .then((resp) => {
        this.loadOrganizations();
      });
    // console.log('INDEXES', ids);
  }

  removeAccountFromOrganization(dataIndex, id) {
    const org = this.organizationsList[dataIndex];
    if (org) {
      return requestPost('/api/organization/removeAccount', {
        organization_id: org.id,
        acc_id: id,
      })
        .then((resp) => {
          const status = get(resp, 'status');
          const message = get(resp, 'message');
          if (status === 'success') {
            runInAction(() => {
              this.loadOrganizations();
            });
            return true;
          }
          return message || 'Network error';
        });
    }
  }
}

const organizationsStorage = new OrganizationsStorage();

export default organizationsStorage;
