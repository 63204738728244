import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router';
import { translate } from 'react-switch-lang';
import { Box, Grid } from '@material-ui/core';
import TextInput from '../components/TextInput';
import CostInput from '../components/CostInput';
import currentProduct from '../../shared/storages/CurrentProduct';
import ColourInput from '../components/ColourInput';
import ArticulSelector from '../containers/ArticulSelector';
import TextInputSelectorBrands from '../components/TextInputSelectorBrands';
import TextInputSelectorGlobalSizes from '../components/TextInputSelectorGlobalSizes';
import FullscreenDialog from '../../shared/components/FullscreenDialog';

import CalendarIcon from '../assets/images/calendar.png';
import NotesIcon from '../assets/images/notes.png';
import InPackage from '../linkedComponents/InPackage';
import Widget from '../../v2/components/Widget';

class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFullscreenDialog: false,
      selectedColor: null,
    };
  }

  onSizeInput = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9,\.\-\s]/ui, '');
    currentProduct.changeField('sizes', inputValue);
  };

  componentDidMount() {
    const {
      editMode,
      brand,
      article,
      match: {
        params: {
          article: articleParam,
          brand: brandParam,
          colorID,
        },
      },
    } = this.props;
    // currentProduct.clearData();
    if (editMode) {
      currentProduct.changeField('brand', brandParam);
      currentProduct.changeField('article', articleParam);
      currentProduct.loadColors();
    } else {
      currentProduct.loadBrands();
    }
    window.addEventListener('popstate', this.onExit);
    // window.onbeforeunload = this.onExit;
  }

  componentWillUnmount() {

  }

  onExit = (e) => {
    const { history } = this.props;
    const r = confirm('You pressed a Back button! Are you sure?!');
    window.removeEventListener('popstate', this.onExit);
    if (r == true) {
      // Call Back button programmatically as per user confirmation.
      history.go(-1);
      return true;
      // Uncomment below line to redirect to the previous page instead.
      // window.location = document.referrer // Note: IE11 is not supporting this.
    }
    // Stay on the current page.
    history.go(1);
    return false;

    // history.pushState(null, null, window.location.pathname);
  };

  showFullscreenDialog = (text) => {
    this.setState((state) => ({ showFullscreenDialog: state.showFullscreenDialog === false ? text : false }));
  };

  render() {
    const {
      editMode,
      match: {
        params: {
          article: articleParam,
          brand: brandParam,
          colorID,
        },
      },
      t,
    } = this.props;
    const { showFullscreenDialog } = this.state;
    return (
      <Widget className="addCard">
        <TextInputSelectorBrands
          id="brand"
          disabled={editMode}
          placeholder="Brand"
          onInput={(e) => currentProduct.changeField('brand', e.target.value)}
          value={currentProduct.brand}
          options={editMode ? [currentProduct.brand] : null}
        />
        <Box className="mb-3">
          <TextInput
            id="article"
            disabled={editMode || currentProduct.brand.length === 0}
            onFocus={currentProduct.loadArticuls}
            placeholder="Article"
            onInput={(e) => currentProduct.changeField('article', e.target.value)}
            value={currentProduct.article}
            onBlur={() => {
              currentProduct.loadSizes();
              currentProduct.loadColors();
            }}
          />
          <ArticulSelector />
        </Box>
        <>
          <Box className="mb-3">
            {(!currentProduct.sizes || currentProduct.sizes.length == 0) ? (
              <TextInputSelectorGlobalSizes
                minTextLenForShowVariants={1}
                allowShowList={false}
                delimiters={[' ']}
                disabled={currentProduct.brand.length === 0 || currentProduct.article.length === 0}
                autoFocus={false}
                onSelectSize={(size) => currentProduct.changeField('sizes', size.sizes)}
                onSave={(data) => currentProduct.changeField('sizes', data.join(' / '))}
                maxVariantsLength={10}
                placeholder="Sizes"
                onError={() => this.showFullscreenDialog(t('dialog1'))}
              />
            ) : (
              <div className="inputBlock" style={{ margin: '10px 0', paddingLeft: 10 }}>
                {currentProduct.sizes}
                &nbsp;
                <i className="fa fa-times round-button" onClick={() => currentProduct.changeField('sizes', '')} />
              </div>
            )}
          </Box>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={6} justify="center" alignItems="center">
                <Box display="flex" alignItems="center">
                  <div className="prependIcon">$</div>
                  <CostInput
                    id="cost"
                    type="number"
                    placeholder="Cost $"
                    disabled={currentProduct.brand.length === 0 || currentProduct.article.length === 0}
                    onInput={(e) => currentProduct.changeField('price', e.target.value)}
                    value={currentProduct.price}
                    step={0.01}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center" flexBasis={1}>
                  <div className="prependIcon">pcs</div>
                  <InPackage />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center" flexBasis={1}>
                  <div className="prependIcon">
                    <img src={CalendarIcon} alt="" />
                  </div>
                  <DatePicker
                    id="actualDate"
                    placeholderText="Actual date"
                    className="form-control fullwidth"
                    dateFormat="dd.MM.yy"
                    autoComplete="off"
                    selected={currentProduct.actualDate}
                    onChange={(date) => currentProduct.changeField('actualDate', date)}
                    disabled={currentProduct.brand.length === 0 || currentProduct.article.length === 0}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <div className="prependIcon">
                    <img src={NotesIcon} alt="" />
                  </div>
                  <TextInput
                    id="comment"
                    disabled={currentProduct.brand.length === 0 || currentProduct.article.length === 0}
                    placeholder="Comment"
                    onInput={(e) => currentProduct.changeField('comment', e.target.value)}
                    value={currentProduct.comment}
                  />
                </Box>
              </Grid>
            </Grid>
          </div>
          <div className="color-selectionContainer">
            {currentProduct
              .colors
              .filter((color) => {
                if (editMode && colorID) {
                  return color.id == colorID;
                }
                return true;
              })
              .map((color, index) => (
                <ColourInput
                  index={index}
                  key={index}
                  tmp={this.state.selectedColor}
                  selected={this.state.selectedColor === color.id}
                  onSelect={(color) => {
                    // this.setState((state) => ({ selectedColor: state.selectedColor === color ? null : color }));
                    this.setState((state) => ({ selectedColor: color }));
                  }}
                />
              ))}
            {currentProduct.canAddColor && <Button color="transparent" onClick={currentProduct.addColor}>Add color</Button>}
          </div>
        </>
        <footer className="sticky-footer">
          <div className="button-group">
            {currentProduct.changed && (
            <Button className="btn newArticleButton saveButton" onClick={currentProduct.saveProduct}>
              Save changes
            </Button>
            )}
            {!currentProduct.changed && (
            <Button className="btn newArticleButton" onClick={currentProduct.newArticle}>
              New article
            </Button>
            )}
          </div>
        </footer>
        {showFullscreenDialog !== false && <FullscreenDialog dialogText={showFullscreenDialog} onClose={() => this.showFullscreenDialog(false)} textOnClose={t('Close')} /> }
      </Widget>
    );
  }
}

AddProduct.defaultProps = {
  editMode: false,
};
AddProduct.propTypes = {
  editMode: PropTypes.bool,
};

export default translate(withRouter(observer(AddProduct)));
