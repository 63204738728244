/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Chip, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import organizationsStorage from '../../shared/storages/OrganizationsStorage';
import brandsStorage from '../../shared/storages/BrandsStorage';

class OrganizationsInBrandsList extends Component {
  onDeleteOrg = (dataIndex, orgID) => {
    const orgIndex = organizationsStorage.organizationsList.findIndex((org) => org.id == orgID);
    if (orgIndex < 0) {
      return false;
    }
    return organizationsStorage
      .removeBrandFromOrganization(orgIndex, brandsStorage.brandsList[dataIndex])
      .then((resp) => {
        if (resp !== true) toast.error(resp);
      });
  };

  render() {
    const { dataIndex, colSpan } = this.props;
    return (
      <TableRow>
        <TableCell colSpan={colSpan} style={{ verticalAlign: 'top' }}>
          <div style={{ verticalAlign: 'top', display: 'block' }}>
            <div>
              <b>Organizations</b>
              <br />
              {brandsStorage.brandsList[dataIndex].organizations.map((organization) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  onDelete={() => this.onDeleteOrg(dataIndex, organization.id)}
                  label={organization.organizationName}
                />
              ))}
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  }
}

OrganizationsInBrandsList.propTypes = {
  colSpan: PropTypes.number.isRequired,
  dataIndex: PropTypes.number.isRequired,
};

export default observer(OrganizationsInBrandsList);
