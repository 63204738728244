import { makeAutoObservable } from 'mobx';

class AlertsStorage {
  alertsList = [];

  constructor() {
    makeAutoObservable(this);
  }
}

const alertsStorage = new AlertsStorage();

export default alertsStorage;
