/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import currentProduct from '../../shared/storages/CurrentProduct';

// eslint-disable-next-line react/prefer-stateless-function
class ArticulSelector extends Component {
  render() {
    return (
      <>
        {currentProduct.selectedArticles.map((article) => (
          <div
            onClick={() => {
              currentProduct.changeField('article', article);
              currentProduct.loadSizes();
              currentProduct.saveProduct();
            }}
          >
            {article}
          </div>
        ))}
      </>
    );
  }
}

ArticulSelector.propTypes = {};

export default observer(ArticulSelector);
