/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

function TextInputSelector({
  id, label, value, disabled, options, onChange, defaultOptionName, defaultOptionValue, style,
}) {
  return (
    <div className="mb-3" style={style}>
      {label && <label htmlFor={id} className="form-label">{label}</label>}
      <select
        disabled={disabled}
        className="form-control"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value={defaultOptionValue}>{defaultOptionName}</option>
        {options && options.map((option) => <option value={option.value}>{option.name.toUpperCase()}</option>)}
      </select>
      {/* <BrandsSelector /> */}
    </div>
  );
}

TextInputSelector.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  id: null,
  label: null,
  onChange: () => {},
  // eslint-disable-next-line react/default-props-match-prop-types
  value: '',
  disabled: false,
  options: [],
  defaultOptionValue: null,
};
TextInputSelector.propTypes = {
  // eslint-disable-next-line react/require-default-props
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onInput: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  defaultOptionName: PropTypes.string.isRequired,
  defaultOptionValue: PropTypes.any,
  onChange: PropTypes.func,
};

export default observer(TextInputSelector);
