/* eslint-disable eqeqeq,jsx-a11y/no-autofocus,react/no-array-index-key,react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Chip } from '@material-ui/core';
import classnames from 'classnames';
import './ChipedAutocompleteInput.scss';
import { translate } from 'react-switch-lang';

class ChipedAutocompleteInput extends Component {
  state = {
    items: [],
    itemText: '',
  };

  removeSize = (index) => this.setState((state) => {
    const { items } = state;
    items.splice(index, 1);
    return { items };
  });

  textChange = (e) => {
    const { value } = e.target;
    const { delimiters } = this.props;
    const currentValue = value;// .replace(/[^0-9\.,\s]/gi, '');
    const key = value.substr(-1);
    if (
      key === String.fromCharCode(13)
      || (delimiters && delimiters.indexOf(key) >= 0)
    ) {
      e.preventDefault();
      e.stopPropagation();
      const { itemText } = this.state;
      const { allowedList, allowedListField, onError } = this.props;
      const newSizeTexts = this.split(itemText);
      let haveErrors = false;
      if (allowedList && allowedList.length > 0) {
        newSizeTexts.map((newSizeText) => {
          const dataIndex = allowedList.findIndex((size) => size[allowedListField] == newSizeText);
          if (dataIndex < 0) {
            haveErrors = true;
          }
          return null;
        });
      }
      if (!haveErrors) {
        this.setState((state) => ({
          items: [...state.items, ...newSizeTexts],
          itemText: '',
        }));
      } else {
        onError();
      }
    } else {
      this.setState({ itemText: currentValue.toUpperCase() });
    }
  };

  split = (text) => {
    const { delimiters } = this.props;
    let newSizeTexts = [];
    if (delimiters) {
      const splitRegexp = new RegExp(`[${delimiters.join('')}]`, '');
      newSizeTexts = text.split(splitRegexp);
    } else {
      newSizeTexts = [text];
    }
    return newSizeTexts;
  };

  sizeKeyPress = (e) => {
    const { delimiters } = this.props;
    if (
      e.key === 'Enter'
      || (delimiters && delimiters.indexOf(e.key) >= 0)
    ) {
      e.preventDefault();
      e.stopPropagation();
      const { itemText } = this.state;
      const { allowedList, allowedListField, onError } = this.props;
      const newSizeTexts = this.split(itemText);
      let haveErrors = false;
      if (allowedList && allowedList.length > 0) {
        newSizeTexts.map((newSizeText) => {
          const dataIndex = allowedList.findIndex((size) => size[allowedListField] == newSizeText);
          if (dataIndex < 0) {
            haveErrors = true;
          }
          return false;
        });
      }
      if (!haveErrors) {
        this.setState((state) => ({
          items: [...state.items, ...newSizeTexts],
          itemText: '',
        }));
      } else {
        onError();
      }
    }
  };

  saveData = () => {
    const { onSave } = this.props;
    const { items, itemText } = this.state;

    if (onSave) {
      if (itemText !== '') {
        const text = this.split(itemText);
        onSave([...items, ...text]);
      } else {
        onSave(items);
      }
      this.setState(() => ({ items: [], itemText: '' }));
    }
  };

  onSelectSizeDefault = (size) => {
    this.setState((state) => ({
      items: [...state.items, size.sizes],
      itemText: '',
    }));
  };

  render() {
    const {
      label,
      id,
      containerClass,
      existingList,
      existingListField,
      removeItem,
      allowShowVariants,
      allowShowList,
      onSave,
      minTextLenForShowVariants,
      onSelectSize,
      autoFocus,
      disabled,
      maxVariantsLength,
      type,
      placeholder,
      allowedList,
      variantsSource,
      filterType,
      t,
    } = this.props;
    const { items, itemText } = this.state;
    let haveSize;
    let sizes;
    const filteredExistingList = variantsSource
      .filter((size) => {
        console.log('WWW', size, existingListField);
        switch (filterType) {
          case 'simpleSingle':
            return (`/ ${size[existingListField] && size[existingListField].toString ? size[existingListField].toString() : 'Err'} /`).includes(`/ ${itemText}`);
          default:
            haveSize = true;
            sizes = [
              ...items,
              itemText,
            ];
            sizes.map((searchedSize) => {
              if (
                !(`/ ${size[existingListField].toString()} /`).includes(`/ ${searchedSize},`)
                && !(`/ ${size[existingListField].toString()} /`).includes(`/ ${searchedSize}`)
              ) haveSize = false;
              return null;
            });
            return haveSize;
        }
      });
    return (
      <div className={classnames({
        'mb-3': true, inputBlock: true, [containerClass]: containerClass, disabled,
      })}
      >
        {label && <label htmlFor={id} className="form-label">{label}</label>}
        {allowShowList && (
        <div className="sizesChips">
          {existingList.map((size) => <Chip key={size} variant="outlined" label={size[existingListField]} onDelete={() => removeItem(size.id)} />)}
        </div>
        )}
        <div className="autocompleteInput">
          <div className="tagInput">
            <div className="chipsGroup">
              {/* eslint-disable-next-line react/no-array-index-key */}
              {items
                .sort()
                .map((item, index) => (
                  <Chip
                    key={`${item}:${index}`}
                    variant="outlined"
                    label={item}
                    onDelete={() => this.removeSize(index)}
                  />
                ))}
            </div>
            <div className="input">
              <input
                className="form-control"
                placeholder={placeholder}
                type={type || 'text'}
                onInput={this.textChange}
                value={itemText}
                onKeyDown={(e) => { this.sizeKeyPress(e); }}
                // onKeyPress={this.sizeKeyPress}
                autoFocus={autoFocus}
                disabled={disabled}
              />
            </div>
          </div>
          {allowShowVariants && itemText.length >= minTextLenForShowVariants && (
            <div className="autocomplete-list">
              {filteredExistingList
                .slice(0, maxVariantsLength)
                .map((size) => (
                  <button type="button" className="autocomplete-item" onClick={() => (onSelectSize ? onSelectSize(size) : this.onSelectSizeDefault(size))}>{size[existingListField]}</button>
                ))}
            </div>
          )}
        </div>
        {onSave
        && (filteredExistingList.length === 0 || items.length > 0)
        && (itemText.length === 0 || allowedList.findIndex((i) => i.sizes === itemText) >= 0)
        && (
        <div className="inputBlock-buttonsGroup">
          <Button variant="contained" onClick={this.saveData}>
            {t('Save')}
          </Button>
        </div>
        )}
      </div>
    );
  }
}

ChipedAutocompleteInput.defaultProps = {
  /**
   * LLL
   */
  label: null,
  id: 'ChipedAutocompleteInput',
  containerClass: null,
  existingList: [],
  removeItem: () => {},
  onSave: () => {},
  onSelectSize: () => {},
  delimiters: [],
  allowedList: [],
  allowedListField: null,
  allowShowVariants: true,
  allowShowList: true,
  onError: () => alert('This size is not allowed'),
  minTextLenForShowVariants: 0,
  // onSelectSize: (size) => console.log('Selected size is:', size),
  maxVariantsLength: 10,
  variantsSource: [],
  existingListField: [],
  autoFocus: false,
  disabled: false,
  type: null,
  placeholder: null,
  filterType: null,
};

ChipedAutocompleteInput.propTypes = {
  /**
   * label
   */
  label: PropTypes.string,
  id: PropTypes.string,
  containerClass: PropTypes.string,
  existingList: PropTypes.array,
  existingListField: PropTypes.string,
  removeItem: PropTypes.func,
  delimiters: PropTypes.array,
  allowedList: PropTypes.array,
  allowedListField: PropTypes.string,
  allowShowVariants: PropTypes.bool,
  allowShowList: PropTypes.bool,
  onError: PropTypes.func,
  onSave: PropTypes.func,
  minTextLenForShowVariants: PropTypes.number,
  onSelectSize: PropTypes.func,
  maxVariantsLength: PropTypes.number,
  variantsSource: PropTypes.array,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  filterType: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default translate(ChipedAutocompleteInput);
