/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-switch-lang';
import './OrderStatus.scss';

class OrderStatus extends PureComponent {
  render() {
    const {
      status, t, className, onClick,
    } = this.props;
    let statusText = 'orderStatus.Unknown';
    let statusClassName = 'unknown';
    switch (+status) {
      case -1:
        statusText = t('orderStatus.Payment error');
        statusClassName = 'error';
        break;
      case 0:
        statusText = t('orderStatus.Unknown');
        break;
      case 1:
        statusText = t('orderStatus.Wait payment');
        statusClassName = 'wait';
        break;
      case 2:
        statusText = t('orderStatus.Prepaid');
        statusClassName = 'prepaid';
        break;
      case 3:
        statusText = t('orderStatus.Fully paid');
        statusClassName = 'paid';
        break;
      case 4:
        statusText = t('orderStatus.Sended');
        statusClassName = 'sended';
        break;
      case 5:
        statusText = t('orderStatus.Delivered');
        statusClassName = 'delivered';
        break;
      default:
        break;
    }
    return <div onClick={onClick} className={`orderStatus__container orderStatus__container_${statusClassName} ${className}`}>{statusText}</div>;
  }
}

OrderStatus.defaultProps = {
  className: '',
  onClick: null,
};

OrderStatus.propTypes = {
  status: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default translate(OrderStatus);
