import { makeAutoObservable } from 'mobx';

class LayoutStorage {
  sidebarVisibility = 'show';

  sidebarOpened = true;

  activeItem = null;

  constructor() {
    makeAutoObservable(this);
  }

  openSidebar = () => { this.sidebarOpened = true; };

  closeSidebar = () => { this.sidebarOpened = false; };

  setActiveItem = (activeItem) => { this.activeItem = activeItem; };
}

const layoutStorage = new LayoutStorage();

export default layoutStorage;
