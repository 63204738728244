import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import localBrowserStorage from '../../shared/storages/LocalBrowserStorage';

class OrderTotal extends Component {
  render() {
    const { id, inPackage, price } = this.props;
    return (localBrowserStorage.products[id] ? localBrowserStorage.products[id].qty : 0) * (inPackage || 0) * (price || 0) / 100;
  }
}

OrderTotal.propTypes = {};

export default observer(OrderTotal);
