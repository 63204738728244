import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toast, ToastContainer } from 'react-toastify';
import { translate } from 'react-switch-lang';
import { applyMiddleware, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { Provider } from 'react-redux';
import Guard from '../shared/Guard';
import reducers from '../v2/reducers';

import MainShop from '../shop/Main';
import MainLayout from './MainLayout';
import Auth from '../auth/main';

// const MainShop = React.lazy(() => import('../shop/Main'));
// const MainLayout = React.lazy(() => import('./MainLayout'));
// const Auth = React.lazy(() => import('../auth/main'));

const store = createStore(
  reducers,
  applyMiddleware(ReduxThunk),
);

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainMenuOpened: false,
    };
  }

  render() {
    return (

      <React.Suspense fallback={() => <>Loading...</>}>
        <Provider store={store}>
          <BrowserRouter>
            <>
              <Switch>
                <Route path="/auth" component={Auth} />
                <Route path="/shop" component={MainShop} />
                <Guard><Route path="/application" component={MainLayout} /></Guard>
              </Switch>
              <ToastContainer />
            </>
          </BrowserRouter>
        </Provider>
      </React.Suspense>

    );
  }
}

Main.propTypes = {};

export default translate(observer(Main));
