import { makeAutoObservable, runInAction } from 'mobx';
import validate from 'validate.js';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import ColorModel from '../models/ColorModel';
import { requestGet, requestPost } from '../real.backend';

class CurrentProduct {
  brand = '';

  article = '';

  actualDate = null;

  sizes = '';

  price = '';

  changed = false;

  colors = [];

  brandsList = [];

  articlesList = [];

  sizesList = [];

  articleSizes = [];

  comment = '';

  inPackage = 0;

  get isReady() {
    return this.changed;
  }

  get selectedBrands() {
    const result = this.brandsList.filter((brand) => brand.toLowerCase().indexOf(this.brand.toLowerCase()) >= 0).slice(0, 10);
    if (result.length == 1) {
      if (result[0] === this.brand) return [];
    }
    return result;
  }

  get selectedArticles() {
    if (this.article.length <= 2) return [];
    const result = this.articlesList.filter((article) => article.toLowerCase().indexOf(this.article.toLowerCase()) == 0).slice(0, 10);
    if (result.length == 1) {
      if (result[0] === this.article) return [];
    }
    return result;
  }

  get selectedSizes() {
    const result = this.sizesList.filter((size) => size.toLowerCase().indexOf(this.sizes.toLowerCase()) >= 0).slice(0, 10);
    if (result.length == 1) {
      if (result[0] === this.sizes) return [];
    }
    return result;
  }

  get canAddColor() {
    if (this.colors.length == 0) {
      return this.brand && this.article;
    }
    const itemsWithoutColors = this.colors.filter((item) => !item.color);
    return itemsWithoutColors.length === 0;
  }

  addColor = () => {
    this.colors.push(new ColorModel());
    this.saveProduct();
  };

  clearData = () => {
    this.brand = '';
    this.article = '';
    this.actualDate = null;
    this.sizes = '';
    this.price = null;
    this.changed = false;
    this.colors = [];
    this.inPackage = 0;
  }

  constructor() {
    makeAutoObservable(this);
  }

  changeField = (field, value) => {
    if (this[field] !== undefined) {
      if (field === 'inPackage') {
        if (value == '') {
          this[field] = 0;
        } else {
          const val = Math.abs(parseInt(value.trim().replace(/[^0-9]/, ''), 10));
          console.log(val);
          if (val >= 0) {
            this[field] = val;
          }
        }
        this.changed = true;
        return;
      }
      if (field === 'brand') {
        this.clearData();
        this.articlesList = [];
        this.brand = value;
        if (this.brandsList.length === 0) this.loadBrands();
        this.sizes = '';
        this.sizesList = [];
        this.colors = [];
        this.price = null;
        this.actualDate = null;
        return;
      }
      if (field === 'sizes') {
        if (value === 'Create sizes') {
        } else {
          this.sizes = value;
          this.changed = true;
        }
        return;
      }
      if (field === 'article') {
        this.article = value;
        this.sizes = '';
        this.sizesList = [];
        this.colors = [];
        this.price = null;
        this.actualDate = null;
        if (this.articlesList.length === 0) this.loadArticuls();
        return;
      }
      if (field == 'price') {
        console.log(value);
        if (value === '') {
          this[field] = '';
          this.changed = true;
          return;
        }
        if (!value) {
          return;
        }
        const newValue = value.replace(/[^0-9\.,]/, '').match(/([0-9]+)([\.,]?)(([0-9]+)?)/);
        let first = '';
        let last = '';
        if (newValue && newValue.length > 0) {
          first = newValue[1];
          last = newValue[3];
        }
        // const last = newValue[1];
        const price = `${first}${last !== '' ? `.${last.substr(0, 2)}` : ''}`;
        this[field] = price;
        this.changed = true;
        return;
      }
      this[field] = value;
      this.changed = true;
    }
  }

  loadProduct = () => {
    requestGet(`/api/products/${this.id}`)
      .then((resp) => {
        if (resp && resp.status === 'success') {
          // All right
          runInAction(() => {
            this.id = resp.product.id;
            this.name = resp.product.name;
            this.brand = resp.product.brand;
            this.article = resp.product.article;
            this.sizes = resp.product.sizes;
            this.inPackage = resp.product.inPackage;
            this.price = resp.product.price ? resp.product.price / 100 : null;
            this.actualDate = resp.product.actualDate ? new Date(resp.product.actualDate) : null;
          });
        } else {
          console.log('ERROR:', resp);
        }
      });
  }

  saveProduct = () => {
    requestPost('/api/products/save', {
      ...this,
      price: this.price * 100,
      attentionDate: this.actualDate ? this.actualDate.toISOString() : null,
      brandsList: undefined,
    })
      .then((resp) => {
        if (resp && resp.status == 'success') {
          this.colors = resp.products ? resp.products.map((product) => new ColorModel(product)) : [];
          if (resp.products.length > 0) {
            this.price = resp.products[0].price ? resp.products[0].price / 100 : null;
            this.actualDate = resp.products[0].attentionDate ? new Date(resp.products[0].attentionDate) : null;
            this.sizes = resp.products[0].sizes ? resp.products[0].sizes : '';
            this.comment = resp.products[0].comment ? resp.products[0].comment : '';
            this.inPackage = resp.products[0].inPackage ? resp.products[0].inPackage : 0;
          }
          this.changed = false;
          toast.info('Updated');
        } else {
          toast.success('Failed');
        }
      });
  }

  loadBrands = () => requestGet('/api/products/brands')
    .then((resp) => {
      if (resp && resp.status == 'success') {
        this.brandsList = resp.brands;
      } else {
        toast.success('Failed');
      }
    })

  loadArticuls = () =>
    // if (this.article && this.article.length > 2) {
    requestPost('/api/products/articles', { brand: this.brand })
      .then((resp) => {
        if (resp && resp.status == 'success') {
          this.articlesList = resp.articles;
        } else {
          toast.success('Failed');
        }
      })
  // }

  loadSizes = () => {
    requestPost('/api/products/sizes', { brand: this.brand, article: this.article })
      .then((resp) => {
        if (resp && resp.status == 'success') {
          this.sizesList = resp.sizes;
          this.articleSizes = [];
          if (resp.article_sizes) {
            resp.article_sizes.map((asize) => {
              if (asize && asize.sizes) this.articleSizes.push(asize.sizes);
            });
          }
          // resp.article_sizes.map(asize => );
          if (resp.sizes.length > 0) {
            // console.log()
            // this.changeField('sizes', resp.sizes[0]);
            this.loadColors();
          }
        } else {
          toast.success('Failed');
        }
      });
  }

  loadColors = () => {
    requestPost('/api/products/colors', { brand: this.brand, article: this.article, sizes: this.sizes })
      .then((resp) => {
        if (resp && resp.status == 'success') {
          this.colors = resp.products ? resp.products.map((product) => new ColorModel(product)) : [];
          if (resp.products.length > 0) {
            this.sizes = resp.products[0].sizes ? resp.products[0].sizes : '';
            this.comment = resp.products[0].comment ? resp.products[0].comment : '';
            this.price = resp.products[0].price ? resp.products[0].price / 100 : null;
            this.actualDate = resp.products[0].attentionDate ? new Date(resp.products[0].attentionDate) : null;
            this.inPackage = resp.products[0].inPackage ? resp.products[0].inPackage : null;
          }
        } else {
          toast.success('Failed');
        }
      });
  }

  removeImage = (index, id) => {
    requestPost('/api/products/images/remove', { id })
      .then((resp) => {
        runInAction(() => {
          const imgIndex = this.colors[index].images.findIndex((f) => f.id == id);
          console.log('REMOVE IMAGE', id, index, imgIndex, this.colors[index]);
          if (imgIndex >= 0) {
            this.colors[index].images.splice(imgIndex, 1);
          }
        });
        if (this.changed) this.saveProduct();
      });
  }

  setChanged = () => {
    this.changed = true;
  }

  newArticle = () => {
    this.article = '';
    this.actualDate = null;
    this.sizes = '';
    this.price = '';
    this.changed = false;
    this.colors = [];
    this.sizesList = [];
  };

  // editProduct = (brand, article, color) => {
  //   if (this.brandsList.length === 0) {
  //     this
  //       .loadBrands()
  //       .then(() => {
  //         runInAction(() => {
  //           this.brand = brand;
  //         });
  //         return this.loadArticuls();
  //       })
  //       .then(() => {
  //         runInAction(() => {
  //           this.article = article;
  //         });
  //         return this.loadColors();
  //       })
  //   }
  // }

  addSize = (brandId, sizes) => requestPost('/api/articles/addSize', {
    brandId, sizes, article: this.article,
  })
    .then((resp) => {
      runInAction(() => {
        const size = get(resp, 'size');
        if (size && size.sizes) {
          const index = this.articleSizes.findIndex((art) => art.id == size.sizes.id);
          if (index < 0) {
            this.articleSizes.push(size.sizes);
          } else {
            toast.info(`Already exists ${index}`);
          }
        } else {
          toast.error('Error');
        }
      });
    })
}

const currentProduct = new CurrentProduct();

export default currentProduct;
