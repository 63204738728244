import { makeAutoObservable } from 'mobx';
import mockedStats from '../../mocks/uploadedPrice.json';

class PriceUploadStorage {
  currentBrand = null;

  currentOrganization = null;

  currentFileName = null;

  fileStat = {};

  fieldsMapper = {};

  fieldsDataMap = {};

  allowFieldsToMapValues = { sizes: true };

  constructor() {
    // this.fileStat = mockedStats.stats;
    // const currentFieldsMapper = {};
    // Object.keys(mockedStats.stats).forEach((key) => currentFieldsMapper[key] = null);
    // this.fieldsMapper = {
    //   'Код Изделия': 'article', Название: 'Ignore', 'Название по Русски': 'name', Пол: 'comment', 'Размер или Возраст': 'sizes', Количество: 'inStock', 'Цена в долларах': 'price', Фото: null,
    // };
    makeAutoObservable(this);
  }

  get mapData() {
    return {
      file: this.currentFileName,
      brand: this.currentBrand,
      organization: this.currentOrganization,
      fieldsMap: this.fieldsMapper,
      dataMap: this.fieldsDataMap,
    };
  }

  setCurrentBrand = (brand) => {
    this.currentBrand = brand;
  };

  setCurrentOrganization = (org) => {
    this.currentOrganization = org;
  };

  setCurrentFileName = (fileName) => {
    this.currentFileName = fileName;
  };

  setFileStat = (stat) => {
    this.fileStat = stat;
    const currentFieldsMapper = {};
    Object.keys(stat).forEach((key) => currentFieldsMapper[key] = 'ignore');
    this.fieldsMapper = currentFieldsMapper;
  };

  setFieldsMap = (map) => {
    this.fieldsMapper = map;
  };

  setMapToFieldData = (data) => {
    this.fieldsDataMap = data;
  };
}

const priceUploadStorage = new PriceUploadStorage();

export default priceUploadStorage;
