import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import { translate } from 'react-switch-lang';
import organizationsStorage from '../../shared/storages/OrganizationsStorage';
import TextInput from '../components/TextInput';

// eslint-disable-next-line react/prefer-stateless-function
class CreateOrganization extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <TextInput
          placeholder="Name"
          value={organizationsStorage.currentOrganization.organizationName}
          onInput={organizationsStorage.changeNewOrganizationName}
        />
        <Button onClick={organizationsStorage.saveCurrentOrg}>{t('Save')}</Button>
      </div>
    );
  }
}

CreateOrganization.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(observer(CreateOrganization));
