import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { getLanguages, translate } from 'react-switch-lang';
import {
  Button, Chip, Input, Select,
} from '@material-ui/core';
import statusesStorage from '../../../shared/storages/StatusesStorage';
import AddStatus from './components/AddStatus';

class Statuses extends Component {
  componentDidMount() {
    statusesStorage.loadStatuses();
  }

  addStatus = (id, e) => {
    console.log(id, e.target.value);
    statusesStorage.addStatus(id, e.target.value);
  };

  saveTranslate = (lang, text) => {};

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="flex-between m-2">
          <AddStatus onSave={statusesStorage.createStatus} />
        </div>
        <div className="flex flex-column">
          {statusesStorage.statusesList.map((status) => (
            <div className="flex flex-between">
              <div>
                {t(`orderStatus.${status.name_en}`)}
              </div>
              <div>
                {status.nexts.map((statusNext) => {
                  const st = statusesStorage.statusesList.find((item) => item.id == statusNext.next);
                  return (
                    <Chip label={t(`orderStatus.${st.name_en}`)} />
                  );
                })}
                {statusesStorage
                  .statusesList
                  .filter((st) => status.nexts.findIndex((s) => s.next == st.id) < 0)
                  .length > 0
                && (
                <Select value={null} onChange={(...props) => this.addStatus(status.id, ...props)}>
                  {statusesStorage
                    .statusesList
                    .filter((st) => status.nexts.findIndex((s) => s.next == st.id) < 0)
                    .map((st) => <option value={st.id}>{t(`orderStatus.${st.name_en}`)}</option>)}
                </Select>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Statuses.propTypes = {};

export default translate(observer(Statuses));
