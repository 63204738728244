import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import './Catalog.scss';
import { Button } from '@material-ui/core';
import { translate } from 'react-switch-lang';
import Card from './elements/cards/Card';
import catalogStorage from './CatalogStorage';
import cartStorage from '../cart/cartStorage';

class Catalog extends Component {
  componentDidMount() {
    catalogStorage.loadPage();
    cartStorage.loadCart();
  }

  render() {
    const { t } = this.props;
    if (catalogStorage.products.length == 0) {
      return <div>{t('No items')}</div>;
    }
    return (
      <div className="catalog-page">
        <div className="catalog-toolbar">
          {catalogStorage.products.length}
          {' '}
          /
          {' '}
          {catalogStorage.total}
        </div>
        <div className="catalog-page__container">
          {catalogStorage.products.map((item) => (
            <Card key={item.id} item={item} />
          ))}
          {catalogStorage.haveMore && (
            <div className="catalog-page__pagination">
              <Button onClick={catalogStorage.loadNextPage}>{t('Load Next')}</Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Catalog.propTypes = {};

export default translate(observer(Catalog));
