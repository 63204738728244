import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { observer } from 'mobx-react';
import brandsStorage from '../../shared/storages/BrandsStorage';
import OrganizationsInBrandsList from '../containers/OrganizationsInBrandsList';

class BrandsPage extends Component {
  componentDidMount() {
    brandsStorage.loadBrands();
  }

  expandableRow = (rowData, { rowIndex, dataIndex }) => {
    const colSpan = rowData.length + 1;
    // return null;
    return (
      <OrganizationsInBrandsList
        colSpan={colSpan}
        rowIndex={rowIndex}
        dataIndex={dataIndex}
      />
    );
  };

  render() {
    const options = {
      expandableRows: true,
      expandableRowsHeader: false,
      expandableRowsOnClick: false,
      isRowExpandable: (dataIndex, expandedRows) => true,
      renderExpandableRow: this.expandableRow,
      downloadOptions: { separator: ';' },
      // onDownload: (buildHead, buildBody, columns, data) => `\uFEFF${buildHead(columns)}${buildBody(data)}`,
      onRowsDelete: ({ data, lookup }, rowsData) => brandsStorage.removeBrandsBulk(data.map((d) => d.dataIndex)),
    };
    const columns = [
      {
        name: 'ID',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const brand = brandsStorage.brandsList[dataIndex];
            if (!brand) return <span>No</span>;
            return brand.id;
          },
        },
      },
      {
        name: 'name',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const brand = brandsStorage.brandsList[dataIndex];
            if (!brand) return <span>No</span>;
            return brand.brand;
          },
        },
      },
      {
        name: 'sizes',
        options: {
          download: false,
          filter: false,
          // display: false,
          customBodyRenderLite: (dataIndex) => {
            const brand = brandsStorage.brandsList[dataIndex];
            if (!brand) return <span>No</span>;
            return brand.sizes.length;
          },
        },
      },
      {
        name: 'organizations',
        options: {
          download: false,
          filter: false,
          // display: false,
          customBodyRenderLite: (dataIndex) => {
            const brand = brandsStorage.brandsList[dataIndex];
            if (!brand) return <span>No</span>;
            return brand.organizations.length;
          },
        },
      },
      // {
      //   name: 'Actions',
      //   options: {
      //     filter: false,
      //     empty: true,
      //     setCellProps: (value) => ({
      //       className: clsx({
      //         // [this.props.classes.NameCell]: value === 'Mel Brooks',
      //       }),
      //       style: {
      //         // borderRight: '2px solid blue'
      //         width: '300px',
      //       },
      //     }),
      //     customBodyRenderLite: (dataIndex) => <AddSizeDialog dataIndex={dataIndex} />,
      //   },
      // },
    ];
    console.log(JSON.parse(JSON.stringify(brandsStorage.brandsList)));
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={() => brandsStorage.loadBrands()}>Reload</Button>
        </div>
        <MUIDataTable
          title="Brands"
          data={brandsStorage.brandsList}
          columns={columns}
          options={options}
        />
      </div>
    );
  }
}

BrandsPage.propTypes = {};

export default observer(BrandsPage);
