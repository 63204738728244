import { makeAutoObservable } from 'mobx';
import { requestGet, requestPost } from '../real.backend';
import { loadTranslations } from '../translations';

class StatusesStorage {
  statusesList = [];

  constructor() {
    makeAutoObservable(this);
  }

  loadStatuses = () => {
    requestGet('/api/dicts/statuses')
      .then((response) => {
        if (response?.status === 'success') {
          this.statusesList = [];
          this.statusesList = response?.statuses || [];
        }
      });
  };

  addStatus = (id, status) => {
    const index = this.statusesList.findIndex((s) => s.id == id);
    if (index >= 0) {
      requestPost('/api/dicts/statuses/addChild', {
        id,
        status,
      })
        .then((response) => {
          if (response?.status === 'success' && response?.newStatus) {
            this.statusesList[index].nexts.push(response.newStatus);
          }
        });
    }
  };

  createStatus = (prefix, translations) => {
    const key = `${prefix}.${translations.en}`;
    requestPost('/api/dicts/statuses/addStatus', {
      key,
      translations,
    })
      .then((response) => {
        if (response?.status === 'success' && response?.newStatus) {
          loadTranslations()
            .then(() => {});
          // this.loadStatuses();
        }
      });
  };
}

const statusesStorage = new StatusesStorage();

export default statusesStorage;
