import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import localBrowserStorage from '../../shared/storages/LocalBrowserStorage';
import productsListStorage from '../../shared/storages/ProductsListStorage';
import organizationOrderStorage from '../containers/OrganizationOrders/OrganizationOrderStorage';

class OrderQty extends Component {
  render() {
    const { dataIndex } = this.props;
    const { id, inPackage, price } = productsListStorage.products[dataIndex];
    const orderIndex = organizationOrderStorage.items.findIndex((item) => item.id == id);
    if (!inPackage || inPackage <= 0 || !price || +price <= 0) return '';
    return (
      <input
        className="form-control"
        style={{ width: 100 }}
        value={organizationOrderStorage.items[orderIndex] ? (organizationOrderStorage.items[orderIndex].qty > 0 ? organizationOrderStorage.items[orderIndex].qty : '') : ''}
        type="number"
        pattern="\d+"
        step={1}
        onInput={(e) => organizationOrderStorage.setItem(id, e.target.value)}
      />
    );
  }
}

OrderQty.propTypes = {};

export default observer(OrderQty);
