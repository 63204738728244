import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import currentProduct from '../../shared/storages/CurrentProduct';
import TextInput from '../components/TextInput';

class InPackage extends Component {
  render() {
    return (
      <TextInput
        id="inPackage"
        type="number"
        disabled={currentProduct.brand.length === 0 || currentProduct.article.length === 0}
        placeholder="In package"
        onInput={(e) => currentProduct.changeField('inPackage', e.target.value)}
        value={currentProduct.inPackage > 0 ? currentProduct.inPackage : ''}
        pattern="\d+"
      />
    );
  }
}

InPackage.propTypes = {};

export default observer(InPackage);
