import { makeAutoObservable, runInAction } from 'mobx';
import { requestPost } from '../../../shared/real.backend';

class CatalogStorage {
  products = [];

  currentPage = 1;

  currentPageSize = 200;

  total = 0;

  filters = [];

  constructor() {
    makeAutoObservable(this);
  }

  get haveMore() {
    return this.total > this.products.length;
  }

  // eslint-disable-next-line no-return-assign
  nextPage = () => this.currentPage += 1;

  loadPage = () => {
    requestPost(`/api/catalog/${this.currentPage}`, {
      pageSize: this.currentPageSize,
      filters: this.filters,
    })
      .then((response) => {
        console.log(response);
        runInAction(() => {
          this.total = +response.total;
          if (this.currentPage <= 1) this.products = [];
          response.products.map((item) => this.products.push(item));
        });
      });
  };

  loadNextPage = () => {
    this.nextPage();
    this.loadPage();
  };

  reload = () => {
    this.currentPage = 1;
    this.loadPage();
  };
}

const catalogStorage = new CatalogStorage();

export default catalogStorage;
