import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import localBrowserStorage from '../../shared/storages/LocalBrowserStorage';

class OrderAmount extends Component {
  render() {
    const { id, inPackage } = this.props;
    return (localBrowserStorage.products[id] ? localBrowserStorage.products[id].qty : 0) * inPackage;
  }
}

OrderAmount.propTypes = {};

export default observer(OrderAmount);
