import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-switch-lang';

function Forbidden({ t }) {
  return (
    <div className="forbidden-panel">{t('Forbidden')}</div>
  );
}

Forbidden.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(Forbidden);
