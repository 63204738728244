import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import organizationsStorage from '../../shared/storages/OrganizationsStorage';

class OrganizationBrandsCount extends Component {
  render() {
    const { dataIndex } = this.props;
    const organization = organizationsStorage.organizationsList[dataIndex];
    if (!organization) return <span>No</span>;
    return organization.brands.length;
  }
}

OrganizationBrandsCount.propTypes = {
  dataIndex: PropTypes.number.isRequired,
};

export default observer(OrganizationBrandsCount);
