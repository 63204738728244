import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getLanguages, translate } from 'react-switch-lang';
import { Button, Input } from '@material-ui/core';

class AddStatus extends Component {
  constructor(props) {
    super(props);
    const state = {};
    getLanguages().map((lang) => state[lang] = '');
    this.state = { ...state };
  }

  changeField = (lang, text) => {
    this.setState((state) => ({ [lang]: text }));
  };

  onClick = () => {
    const { onSave } = this.props;
    if (onSave) {
      onSave('orderStatus', this.state);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        {getLanguages().map((lang) => (
          <div>
            <Input
              value={this.state[lang]}
              startAdornment={<span className="p-1 mr-2" style={{ backgroundColor: 'lightgray' }}>{lang}</span>}
              name={lang}
              onChange={(e) => this.changeField(lang, e.target.value)}
            />
          </div>
        ))}
        <Button variant="outlined" onClick={this.onClick}>{t('Add')}</Button>
      </>
    );
  }
}

AddStatus.propTypes = {
  t: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default translate(AddStatus);
