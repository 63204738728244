import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate } from 'react-switch-lang';
import {
  Table, TableBody, TableCell, TableRow,
} from '@material-ui/core';
import organizationsStorage from '../../shared/storages/OrganizationsStorage';
import BrandAndAccountList from '../containers/BrandAndAccountList';
import AddBrandDialog from '../components/AddBrandDialog';

class Profile extends Component {
  render() {
    return (
      <div>
        <h2>{organizationsStorage.myOrganization.organizationName}</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <AddBrandDialog dataIndex={organizationsStorage.myOrganizationIndex} />
              </TableCell>
            </TableRow>
            <BrandAndAccountList
              colSpan={1}
              rowIndex={organizationsStorage.myOrganizationIndex}
              dataIndex={organizationsStorage.myOrganizationIndex}
            />
          </TableBody>
        </Table>
      </div>
    );
  }
}

Profile.propTypes = {};

export default translate(observer(Profile));
