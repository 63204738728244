import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import { requestPost } from '../real.backend';

class BrandsStorage {
  brandsList = [];

  constructor() {
    makeAutoObservable(this);
  }

  loadBrands = (filter = null) => {
    console.log('LOAD BRANDS');
    requestPost('/api/brands/all', { filter })
      .then((resp) => {
        runInAction(() => {
          if (resp && resp.brands) {
            this.brandsList = resp.brands;
            console.log(this.brandsList);
          }
        });
      });
  }

  addSize = (dataIndex, sizesList) => {
    requestPost('/api/brands/addSize', {
      brand_id: this.brandsList[dataIndex].id,
      sizes: sizesList.filter((value) => +(value.trim()) > 0),
    })
      .then((resp) => {
        runInAction(() => {
          if (resp && resp.size) {
            this.brandsList[dataIndex].sizes = uniqWith([
              ...this.brandsList[dataIndex].sizes,
              ...resp.size,
            ], (a, b) => a.id === b.id);
          } else {
            toast.error('Something wrong sizes');
          }
          if (resp && resp.sizes_list) {
            this.brandsList[dataIndex].sizes_list = uniqWith([
              ...this.brandsList[dataIndex].sizes_list,
              ...resp.sizes_list,
            ], (a, b) => a.id === b.id);
            toast.success('Added');
          }
          if (resp && resp.sizeList) {
            this.brandsList[dataIndex].sizes_list = uniqWith([
              ...this.brandsList[dataIndex].sizes_list,
              resp.sizeList,
            ], (a, b) => a.id === b.id);
            toast.success('Added');
          } else {
            toast.error('Something wrong sizes list 2');
          }
        });
      });
  }

  removeSize = (dataIndex, sizeID) => {
    requestPost('/api/brands/removeSize', {
      brand_id: this.brandsList[dataIndex].id,
      sizeID,
    })
      .then((resp) => {
        const status = get(resp, 'status');
        if (status === 'success') {
          const removedSizeId = get(resp, 'removedSize');
          const index = this.brandsList[dataIndex].sizes.findIndex((size) => size.id == removedSizeId);
          if (index >= 0) {
            this.brandsList[dataIndex].sizes.splice(index, 1);
          }
        }
      });
  }

  removeBrandsBulk(indexes) {
    const ids = [];
    return Promise.all(indexes.map((dataIndex) => requestPost('/api/brands/remove', {
      brand_id: this.brandsList[dataIndex].id,
    })
      .then((resp) => {
        const status = get(resp, 'status');
        const message = get(resp, 'message');
        if (status === 'success') {
          return true;
        }
        return message || 'Network error';
      })))
      .then((resp) => {
        this.loadBrands();
      });
  }
}

const brandsStorage = new BrandsStorage();

export default brandsStorage;
