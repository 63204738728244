import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import { translate } from 'react-switch-lang';
import TextInput from './TextInput';
import sizesStorage from '../../shared/storages/SizesStorage';

class AddGlobalSizeDialog extends Component {
  state = {
    currentSizes: '',
  };

  setCurrentSize = (e) => {
    const inputValue = e.target.value;
    // inputValue = inputValue.replace(/[^0-9,\.\-\s]/ui, '');
    this.setState(() => ({ currentSizes: inputValue.toUpperCase() }));
  };

  saveSize = () => {
    const { currentSizes } = this.state;
    if (currentSizes) {
      const sizes = [currentSizes];
      sizesStorage.addSizes(sizes)
        .then((resp) => {
          if (resp === true) {
            this.setState(() => ({ currentSizes: '' }));
          } else {
            toast.error('Size was not added');
          }
        });
    }
  };

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      this.saveSize();
    }
  };

  render() {
    const { t } = this.props;
    const { currentSizes } = this.state;
    return (
      <div>
        <div>{t('Add size')}</div>
        <TextInput
          id="Sizes"
          placeholder={t('Sizes')}
          onInput={this.setCurrentSize}
          value={currentSizes}
          onKeyPress={this.onKeyPress}
        />
        <Button variant="outlined" size="small" onClick={this.saveSize}>
          {t('Add size')}
        </Button>
      </div>
    );
  }
}

AddGlobalSizeDialog.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(AddGlobalSizeDialog);
