import { makeAutoObservable, runInAction } from 'mobx';
import uniqWith from 'lodash/uniqWith';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { requestPost } from '../real.backend';
import sortSizes from '../helpers/sortSizes';

class SizesStorage {
  sizes = [];

  organizationSizes = [];

  constructor() {
    makeAutoObservable(this);
  }

  loadOrganizationSizes = () => requestPost('/api/organization/size/all', {})
    .then((response) => {
      const sizes = get(response, 'organization_sizes');
      if (sizes) {
        runInAction(() => {
          this.organizationSizes = [];
          this.organizationSizes = sizes.sort(sortSizes);
        });
        return true;
      }
      toast.error('Sizes was not loaded');
      return false;
    })

  loadSizes = () => requestPost('/api/size/all', {})
    .then((response) => {
      const sizes = get(response, 'sizes');
      if (sizes) {
        runInAction(() => {
          this.sizes = [];
          this.sizes = sizes.sort(sortSizes);
        });
        return true;
      }
      toast.error('Sizes was not loaded');
      return false;
    })

  addSize = (size) => {
  }

  addSizes = (sizes, brandID = 0) => requestPost('/api/size/add', {
    brand_id: brandID,
    sizes,
  })
    .then((response) => {
      const newSizes = get(response, 'sizes');
      if (newSizes) {
        runInAction(() => {
          const newSizesList = uniqWith([
            ...this.sizes,
            ...newSizes,
          ], (a, b) => a.id === b.id);
          this.sizes = newSizesList.sort(sortSizes);
        });
        return true;
      }
      return false;
    })

  addSizesRow = (sizes, brandID = 0, organizationID = null) => requestPost('/api/organization/size/add', {
    brand_id: brandID,
    organization_id: organizationID,
    sizes,
  })
    .then((response) => {
      const newSizes = get(response, 'organization_sizes');
      if (newSizes) {
        runInAction(() => {
          const newSizesList = uniqWith([
            ...this.organizationSizes,
            ...newSizes,
          ], (a, b) => a.id === b.id);
          this.organizationSizes = newSizesList.sort(sortSizes);
        });
        return true;
      }
      return false;
    })

  removeSize = (sizes) => requestPost('/api/size/remove', {
    brand_id: 0,
    sizes,
  })
    .then((response) => {
      const newSizes = get(response, 'sizes');
      if (newSizes) {
        runInAction(() => {
          newSizes.map((ns) => {
            const newSizeIndex = this.sizes.findIndex((a) => a.id == ns);
            if (newSizeIndex >= 0) {
              this.sizes.splice(newSizeIndex, 1);
            }
          });
        });
        return true;
      }
      return false;
    })

  removeGlobalSize = (sizes) => requestPost('/api/organization/size/remove', {
    brand_id: 0,
    organization_id: 0,
    sizes,
  })
    .then((response) => {
      const newSizes = get(response, 'sizes');
      if (newSizes) {
        runInAction(() => {
          newSizes.map((ns) => {
            const newSizeIndex = this.organizationSizes.findIndex((a) => a.id == ns);
            if (newSizeIndex >= 0) {
              this.organizationSizes.splice(newSizeIndex, 1);
            }
          });
        });
        return true;
      }
      return false;
    })
}

const sizesStorage = new SizesStorage();

export default sizesStorage;
