import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import { translate } from 'react-switch-lang';
import OrderHeader from './OrderHeader';
import OrderItems from './OrderItems';
import organizationOrderStorage from './OrganizationOrderStorage';

class OrganizationOrders extends Component {
  componentDidMount() {
    organizationOrderStorage.loadCurrentOrder();
  }

  render() {
    const { t } = this.props;
    return (
      <div className="organizationOrders">
        <Paper>
          <Box flex margin={1}>
            {t('Current order')}
            <OrderHeader />
            <OrderItems />
          </Box>
        </Paper>
      </div>
    );
  }
}

OrganizationOrders.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(OrganizationOrders);
