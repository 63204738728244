import React, { Component } from 'react';
import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import brandsStorage from '../../shared/storages/BrandsStorage';
import sizesStorage from '../../shared/storages/SizesStorage';
import AddGlobalSizeDialog from '../components/AddGlobalSizeDialog';
import sortSizes from '../../shared/helpers/sortSizes';

class GlobalSizesList extends Component {
  onDelete = (sizeID) => {
    const { dataIndex } = this.props;
    brandsStorage.removeSize(dataIndex, sizeID);
  };

  onDeleteSize = (id) => {
    sizesStorage.removeSize([id]);
  };

  render() {
    return (
      <div style={{ verticalAlign: 'top', display: 'block' }}>
        <div className="sizesChips">
          {sizesStorage.sizes.slice(0, sizesStorage.sizes.length).sort(sortSizes).map((size) => (
            <Chip
              variant="outlined"
              color="primary"
              onDelete={() => this.onDeleteSize(size.id)}
              label={size.sizes}
            />
          ))}
        </div>
        <div>
          <AddGlobalSizeDialog />
        </div>
      </div>
    );
  }
}

GlobalSizesList.propTypes = {
  dataIndex: PropTypes.number.isRequired,
};

export default observer(GlobalSizesList);
