import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { translate } from 'react-switch-lang';
import organizationsStorage from '../../shared/storages/OrganizationsStorage';
import AddTextDialog from '../containers/AddTextDialog';
import FalsePromise from '../../shared/helpers/FalsePromise';
import AddAccountDialog from '../containers/AddAccountDialog';

class AddBrandDialog extends Component {
  state = {
    addBrandToOrganization: false,
    addAccountToOrganization: false,
    addAccountToOrganizationPassword: false,
    addAccountToOrganizationName: false,
  };

  addBrandToOrganization = (value) => {
    this.setState(() => ({ addBrandToOrganization: value }));
  };

  addAccountToOrganization = (value) => {
    this.setState(() => ({ addAccountToOrganization: value }));
  };

  addAccountPasswordToOrganization = (value) => {
    this.setState(() => ({ addAccountToOrganizationPassword: value }));
  };

  addAccountToOrganizationName = (value) => {
    this.setState(() => ({ addAccountToOrganizationName: value }));
  };

  toggleAddBrandDialog = () => {
    this.setState((state) => ({
      addBrandToOrganization: (state.addBrandToOrganization === false ? '' : false),
    }));
  };

  toggleAddAccountDialog = () => {
    this.setState((state) => ({
      addAccountToOrganization: (state.addAccountToOrganization === false ? '' : false),
      addAccountToOrganizationPassword: (state.addAccountToOrganizationPassword === false ? '' : false),
      addAccountToOrganizationName: (state.addAccountToOrganizationName === false ? '' : false),
    }));
  };

  saveBrand = (text) => {
    const { dataIndex } = this.props;
    if (text) {
      return organizationsStorage
        .addBrandToOrganization(dataIndex, text);
    }
    return FalsePromise();
  };

  saveAccount = (name, email, password) => {
    const { dataIndex } = this.props;
    return organizationsStorage
      .addAccountToOrganization(dataIndex, email, password, name);
  };

  render() {
    const { t } = this.props;
    const {
      addBrandToOrganization,
      addAccountToOrganization,
    } = this.state;
    return (
      <>
        {addBrandToOrganization !== false && (
          <AddTextDialog
            saveButtonText={t('Save brand')}
            closeButtonText={t('Cancel')}
            onSave={(text) => this.saveBrand(text)}
            onCancel={() => this.setState(() => ({ addBrandToOrganization: false }))}
            placeholder={t('Brand')}
          />
        )}
        {addAccountToOrganization !== false && (
          <AddAccountDialog
            onSave={this.saveAccount}
            onCancel={() => this.setState(() => ({ addAccountToOrganization: false }))}
          />
        )}
        {addAccountToOrganization === false && addBrandToOrganization === false && (
          <>
            <Button variant="outlined" size="small" onClick={this.toggleAddBrandDialog}>
              {t('Add Brand')}
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={addAccountToOrganization === false
                ? this.toggleAddAccountDialog
                : this.saveAccount}
            >
              {addAccountToOrganization === false ? t('Add Account') : 'Save Account'}
            </Button>
            {addAccountToOrganization !== false && (
              <Button variant="outlined" size="small" onClick={this.toggleAddAccountDialog}>
                {t('Cancel')}
              </Button>
            )}
            {addBrandToOrganization !== false && (
              <Button variant="outlined" size="small" onClick={this.toggleAddAccountDialog}>
                {t('Cancel')}
              </Button>
            )}
          </>
        )}
      </>
    );
  }
}

AddBrandDialog.propTypes = {
  dataIndex: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(AddBrandDialog);
