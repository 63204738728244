import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { requestPost } from '../../../shared/real.backend';

class OrdersStorage {
  ordersList = [];

  currentOrderID = null;

  currentOrderItems = [];

  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentOrder = (id) => {
    this.currentOrderItems = [];
    this.currentOrderID = this.currentOrderID === id ? null : id;
    if (this.currentOrderID === id) {
      this.loadOrderDetails(id);
    }
  };

  loadOrderDetails = (id) => {
    this.loading = true;
    requestPost('/api/orders/detail', {
      order_id: id,
    })
      .then((response) => {
        if (response?.status == 'success') {
          this.currentOrderItems = response.orderDetail || {};
        }
        this.loading = false;
      });
  };

  loadOrders = () => {
    requestPost('/api/orders/all', {})
      .then((response) => {
        console.log(response);
        if (response && response.status === 'success') {
          runInAction(() => {
            this.ordersList = [];
            response.orders.reverse().map((order) => this.ordersList.push(order));
          });
        }
      });
  };

  loadOrdersIfNotLoaded = () => {
    if (this.ordersList.length === 0) {
      this.loadOrders();
    }
  };

  changeOrderStatus = (index, value) => {
    if (this.ordersList[index]) {
      this.changeOrderStatusOnServer(index, value);
    }
  };

  changeOrderStatusOnServer = (index, value) => {
    requestPost('/api/orders/changeStatus', {
      id: this.ordersList[index].id,
      status: value,
    })
      .then((response) => {
        if (response?.status === 'success') {
          runInAction(() => {
            this.ordersList[index].status = value;
          });
        } else if (response?.message) {
          toast.error(response?.message);
        } else {
          toast.error('Server error');
        }
      });
  };
}

const ordersStorage = new OrdersStorage();

export default ordersStorage;
