import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import TextInput from '../components/TextInput';

function AddTextDialog(
  {
    onSave,
    onCancel,
    placeholder,
    saveButtonText,
    closeButtonText,
  },
) {
  const [currentText, setCurrentText] = useState('');
  return (
    <div>
      <TextInput
        id="brand"
        placeholder={placeholder}
        onInput={(e) => setCurrentText(e.target.value)}
        value={currentText}
      />
      <Button variant="outlined" size="small" onClick={() => onSave(currentText).then((r) => (r ? setCurrentText('') : false))}>
        {saveButtonText || t('Save')}
      </Button>
      <Button variant="outlined" size="small" onClick={() => onCancel()}>
        {closeButtonText || t('Close')}
      </Button>

    </div>
  );
}

AddTextDialog.defaultProps = {
  placeholder: '',
  saveButtonText: null,
  closeButtonText: null,
};

AddTextDialog.propTypes = {
  placeholder: PropTypes.string,
  saveButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddTextDialog;
