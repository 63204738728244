import './bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Main from './application/Main';
import { loadTranslations } from './shared/translations';
import './sass/app.scss';
import './css/app.css';
import './shop/scss/main.scss';
import './v2/styles/theme.scss';

if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    dsn: 'https://d5d7922913a542c5b0380fa5974910db@o463725.ingest.sentry.io/5667877',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
  });
}

loadTranslations().then((r) => {
  ReactDOM.render(<Main />, document.getElementById('root'));
});

if (module.hot) {
  module.hot.accept('./App');
}
