/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Button, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate } from 'react-switch-lang';
import organizationsStorage from '../../shared/storages/OrganizationsStorage';
import brandsStorage from '../../shared/storages/BrandsStorage';

// eslint-disable-next-line react/prefer-stateless-function
class BrandAndAccountList extends Component {
  render() {
    const { t, dataIndex, colSpan } = this.props;
    if (!organizationsStorage.organizationsList[dataIndex]) return null;
    return (
      <TableRow>
        <TableCell colSpan={colSpan} style={{ verticalAlign: 'top' }}>
          <div style={{ verticalAlign: 'top', display: 'flex', justifyContent: 'space-around' }}>
            <div>
              <b>{t('Accounts')}</b>
              <br />
              <table>
                {organizationsStorage.organizationsList[dataIndex].accounts.map((account) => (
                  <tr>
                    <td>{account.name}</td>
                    <td><a href={`mailto:${account.email}`}>{account.email}</a></td>
                    <td>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => organizationsStorage
                          .removeAccountFromOrganization(dataIndex, account.id)}
                      >
                        <i className="fa fa-times" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
            <div>
              <b>{t('Brands')}</b>
              <br />
              <table>
                {organizationsStorage.organizationsList[dataIndex].brands.map((brand) => (
                  <tr>
                    <td>{brand.brand}</td>
                    <td>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          const brIndex = brandsStorage
                            .brandsList
                            .findIndex((br) => br.id == brand.brand_id);
                          if (brIndex >= 0) {
                            organizationsStorage
                              .removeBrandFromOrganization(
                                dataIndex,
                                brandsStorage.brandsList[brIndex],
                              )
                              .then(() => {});
                          }
                        }}
                      >
                        <i className="fa fa-times" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  }
}

BrandAndAccountList.propTypes = {
  t: PropTypes.func.isRequired,
  dataIndex: PropTypes.number.isRequired,
  colSpan: PropTypes.number.isRequired,
};

export default translate(observer(BrandAndAccountList));
