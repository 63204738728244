import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import currentUserStorage from './storages/CurrentUserStorage';
import brandsStorage from './storages/BrandsStorage';
import sizesStorage from './storages/SizesStorage';
import organizationsStorage from './storages/OrganizationsStorage';
import statusesStorage from './storages/StatusesStorage';

function Guard({ children }) {
  useEffect(() => {
    currentUserStorage.loadCurrentUser()
      .then((success) => {
        if (success) {
          brandsStorage.loadBrands();
          sizesStorage.loadSizes();
          sizesStorage.loadOrganizationSizes();
          organizationsStorage.loadOrganizations();
          statusesStorage.loadStatuses();
        } else if (!window.location.href.includes('/auth')) {
          window.location.href = '/auth/login';
        }
      })
      .catch(() => {
        toast.error('User is not authentificated');
        window.location.href = '/auth/login';
      });
  }, []);

  return children;
}

export default Guard;
