import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-switch-lang';
import { observer } from 'mobx-react';
import TextInput from '../../components/TextInput';
import organizationOrderStorage from './OrganizationOrderStorage';

// eslint-disable-next-line react/prefer-stateless-function
class OrderHeader extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <button
            disabled={!organizationOrderStorage.isChanged}
            type="button"
            onClick={organizationOrderStorage.saveChanges}
          >
            Save
          </button>
          <button
            disabled={organizationOrderStorage.isChanged}
            type="button"
            onClick={organizationOrderStorage.newOrder}
          >
            New
          </button>
        </div>
        <h3>Header</h3>
        <TextInput
          className={organizationOrderStorage.changed.name !== undefined ? 'changedField' : ''}
          value={organizationOrderStorage.name}
          onInput={(e) => organizationOrderStorage.changeField('name', e.target.value)}
          label={t('Name')}
        />
        <TextInput
          className={organizationOrderStorage.changed.surname !== undefined ? 'changedField' : ''}
          value={organizationOrderStorage.surname}
          onInput={(e) => organizationOrderStorage.changeField('surname', e.target.value)}
          label={t('Surname')}
        />
        <TextInput
          className={organizationOrderStorage.changed.town !== undefined ? 'changedField' : ''}
          value={organizationOrderStorage.town}
          onInput={(e) => organizationOrderStorage.changeField('town', e.target.value)}
          label={t('City')}
        />
        <TextInput
          className={organizationOrderStorage.changed.phone !== undefined ? 'changedField' : ''}
          value={organizationOrderStorage.phone}
          onInput={(e) => organizationOrderStorage.changeField('phone', e.target.value)}
          label={t('Phone')}
        />
        <TextInput
          className={organizationOrderStorage.changed.email !== undefined ? 'changedField' : ''}
          value={organizationOrderStorage.email}
          onInput={(e) => organizationOrderStorage.changeField('email', e.target.value)}
          label={t('Email')}
        />
        <TextInput
          className={organizationOrderStorage.changed.addr !== undefined ? 'changedField' : ''}
          value={organizationOrderStorage.addr}
          onInput={(e) => organizationOrderStorage.changeField('addr', e.target.value)}
          label={t('Address')}
        />
        <TextInput
          className={organizationOrderStorage.changed.cargo !== undefined ? 'changedField' : ''}
          value={organizationOrderStorage.cargo}
          onInput={(e) => organizationOrderStorage.changeField('cargo', e.target.value)}
          label={t('cargo')}
        />
        <TextInput
          className={organizationOrderStorage.changed.cargoCode !== undefined ? 'changedField' : ''}
          value={organizationOrderStorage.cargoCode}
          onInput={(e) => organizationOrderStorage.changeField('cargoCode', e.target.value)}
          label={t('cargoCode')}
        />
      </div>
    );
  }
}

OrderHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(observer(OrderHeader));
