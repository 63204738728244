/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import cartStorage from './cartStorage';
import CartItem from './CartItem';
import './Cart.scss';

class CartPage extends Component {
  componentDidMount() {
    cartStorage.loadCart();
  }

  render() {
    if (cartStorage.loading) return null;
    const { history, t } = this.props;
    return (
      <div>
        <div className="cart__total">
          {t('cart.Total')}
          {' '}
          : $
          {(cartStorage.currentCart.reduce((prev, current) => {
            const { packs, product_id } = current;
            if (!cartStorage.products[product_id]) return 0;
            const { price, inPackage } = cartStorage.products[product_id];
            return prev + packs * price * inPackage;
          }, 0) / 100).toFixed(2)}
        </div>
        <div className="cart__container">
          {cartStorage
            .currentCart
            .filter((cartItem) => cartItem.packs > 0)
            .map((cartItem) => <CartItem index={cartItem.product_id} key={cartItem.id} />)}
        </div>
        {cartStorage.currentCart.filter((cartItem) => cartItem.packs > 0).length > 0 && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <button
            type="button"
            onClick={() => {
              cartStorage
                .createOrder()
                .then((response) => {
                  if (response?.status === 'success') {
                    history.push(`/application/client/order/${response.order.id}/success`);
                  }
                });
            }}
          >
            {t('cart.Create Order')}
          </button>
        </div>
        )}
      </div>
    );
  }
}

CartPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(withRouter(observer(CartPage)));
