/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Card.scss';
import { translate } from 'react-switch-lang';
import { Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import MinusIcon from '@material-ui/icons/Remove';
import ColorButtons from '../../../../assets/images/color-buttons.png';
import cartStorage from '../../../cart/cartStorage';

// eslint-disable-next-line react/prefer-stateless-function
class Card extends Component {
  render() {
    const {
      item: {
        article,
        attentionDate,
        brand,
        color,
        id,
        images,
        inPackage,
        price,
        sizes,
      },
      t,
    } = this.props;
    const dueDate = attentionDate ? new Date(attentionDate) : null;
    const inPlace = attentionDate ? new Date(attentionDate) : null;
    if (inPlace) inPlace.setDate(inPlace.getDate() + 10);
    return (
      <div className={`product-card product-card__container product-card__container_${window.location.hostname}`}>
        <div className="product-card__top-container">
          <div className="product-card__header">
            <div className="product-card__article">{article}</div>
            <div className="product-card__brand">{brand}</div>
          </div>
          <div className="product-card__image" style={{ backgroundImage: `url(${images.length > 0 ? images[0].thumbnail : 'NoImage.jpg'})` }}>
          &nbsp;
          </div>
        </div>
        <div className="product-card__bottom-container">
          <div className="product-card__colors">
            <div className="product-card__color">{color}</div>
            <div className="product-card__colorSearch">
              <Button>
                <img src={ColorButtons} loading="lazy" alt="" />
              </Button>
            </div>
          </div>
          <div className="product-card__sizes">
            {sizes && sizes.split(',').map((size) => <div className="product-card__size">{size}</div>)}
          </div>
          <div className="product-card__prices">
            <div className="product-card__price">
              $
              {price ? (price / 100).toFixed(2) : '0.00'}
            </div>
            <div className="product-card__due">
              {dueDate && `${t('Due to')} ${dueDate.getDate().toString().padStart(2, '0')}.${dueDate.getMonth().toString().padStart(2, '0')}`}
            </div>
          </div>
          <div className="product-card__buttons">
            <div aria-hidden="true" className="product-card__minus-button" onClick={() => cartStorage.removeFromCart(id)}>
              <MinusIcon />
            </div>
            <div className="product-card__in-cart">
              {cartStorage.cartItems[id] ? cartStorage.cartItems[id].packs : 0}
            </div>
            <div aria-hidden="true" className="product-card__buy-button" onClick={() => cartStorage.addToCart(id)}>
              +
              {inPackage}
            </div>
          </div>
          <div className="product-card__delivered">
            {inPlace && `${t('Delivery at')} ${inPlace.getDate().toString().padStart(2, '0')}.${inPlace.getMonth().toString().padStart(2, '0')}.${inPlace.getFullYear()}`}
          </div>
        </div>
      </div>
    );
  }
}

Card.defaultProps = {
  item: {
    article: null,
    attentionDate: null,
    brand: null,
    color: null,
    id: null,
    images: [],
    inPackage: 0,
    price: 0,
    sizes: 0,
  },
};

Card.propTypes = {
  item: PropTypes.shape({
    article: PropTypes.string,
    attentionDate: PropTypes.any,
    brand: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.number,
    images: PropTypes.array,
    inPackage: PropTypes.number,
    price: PropTypes.number,
    sizes: PropTypes.array,
  }),
  t: PropTypes.func.isRequired,
};

export default translate(observer(Card));
