import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate } from 'react-switch-lang';
import GlobalSizesList from '../containers/GlobalSizesList';
import TextInputSelectorGlobalSizes from '../components/TextInputSelectorGlobalSizes';
import sizesStorage from '../../shared/storages/SizesStorage';
import currentUserStorage from '../../shared/storages/CurrentUserStorage';

class SystemSettingsPage extends Component {
  render() {
    const { t } = this.props;
    if (!currentUserStorage.isAdmin) return <div>Forbidden</div>;
    console.log('WWW', sizesStorage.sizes);
    return (
      <div>
        <div>
          {t('Sizes')}
          :
          <GlobalSizesList />
        </div>
        <div>
          {t('Global sizes')}
          :
          <TextInputSelectorGlobalSizes
            id="sizes"
            // onFocus={currentProduct.loadSizes}
            placeholder={t('Size')}
            onInput={this.onSizeInput}
            disabled={false}
            onSave={(sizes) => sizesStorage.addSizesRow(sizes, 0, 0)}
            removeItem={(id) => sizesStorage.removeGlobalSize([id])}
            variantsSource={sizesStorage.sizes}
            filterType="simpleSingle"
          />
        </div>
      </div>
    );
  }
}

SystemSettingsPage.propTypes = {};

export default translate(observer(SystemSettingsPage));
