/* eslint-disable react/jsx-props-no-spreading,react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class IbSpan extends Component {
  render() {
    const { children, className, ...otherProps } = this.props;
    let cls = 'ib-span';
    if (className) cls = `${cls} ${className}`;
    return (
      <span className={cls} {...otherProps}>
        {children}
      </span>
    );
  }
}

IbSpan.defaultProps = {
  className: '',
  children: [],
};

IbSpan.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  className: PropTypes.string,
};

export default IbSpan;
