import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import { translate } from 'react-switch-lang';
import catalogStorage from '../../application/pages/catalog/CatalogStorage';
import cartStorage from '../../application/pages/cart/cartStorage';
import Card from '../../application/pages/catalog/elements/cards/Card';

function Catalog({ t }) {
  useEffect(() => {
    catalogStorage.loadPage();
    cartStorage.loadCart();
  }, []);
  if (catalogStorage.products.length == 0) {
    return <div>{t('No items')}</div>;
  }
  return (
    <div className="catalog-page">
      <div className="catalog-page">
        <div className="catalog-toolbar">
          {catalogStorage.products.length}
          {' '}
          /
          {' '}
          {catalogStorage.total}
        </div>
        <div className="catalog-page__container">
          {catalogStorage.products.map((item) => (
            <Card key={item.id} item={item} />
          ))}
          {catalogStorage.haveMore && (
            <div className="catalog-page__pagination">
              <Button onClick={catalogStorage.loadNextPage}>{t('Load Next')}</Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default translate(observer(Catalog));
